@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.phase-badge-container {
  width: 188px;
  align-self: center;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding-bottom: 10px;
}

.phase-badge {
  @extend .flex-centered;
  border-radius: 3px;
  border: none;
  color: $white;
  cursor: pointer;
  font-size: $caption-text;
  font-weight: 500;
  height: 18px;
  width: 40px;

  &--off {
    background-color: $dark-gray !important;
    color: $dark-charcoal !important;

    @include applyTheme($themes) {
      background-color: themed('background-light') !important;
    }
  }

  &--disabled {
    background-color: $dark-gray !important;
    cursor: not-allowed;

    @include applyTheme($themes) {
      color: themed('text-disabled') !important;
    }
  }
}

.light .phase-badge {
  color: #FFFFFF;
  &--off {
    background-color: #FFFFFF !important;
    color: #606060 !important;
    border: 1px solid #606060;
  }

  &--disabled {
    background-color: #e3e3e3 !important;
    color: #aaaaaa !important;
  }
}

.dark .phase-badge {
  color: $dark-charcoal;
  &--off {
    background-color: #707070 !important;
    color: #FFFFFF !important;
  }

  &--disabled {
    background-color: #424242 !important;
    color: #7b7b7b !important;
  }
}