@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.control-mode {
  padding: 5px 0;

  .help-icon {
    user-select: none;
    margin-left: 10px;

    @include applyTheme($themes) {
      color: themed('text-main');
    }
  }

  .tooltip {
    min-width: 34.5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tooltip-contents {
      font-size: $caption-text;
      line-height: 18px;
      min-width: 200px;
      padding: 8px;
      right: 30px;
      @include applyTheme($themes) {
        background-color: themed('background-neutral');
        color: themed('text-main');
      }
    }
  }

  .control-mode-aux-content {
    display: flex;
    flex-direction: column;
    .control-mode-title {
      font-size: $title-text;
      line-height: 30px;
    }
    .control-mode-select {
      display: flex;
      width: 230px;
    }
  }
}
