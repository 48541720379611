@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.drag-drop {
  border: 2px dashed $light-gray;
  border-radius: 4px;
  width: 100%;
  @include applyTheme($themes) {
    border-color: themed('background-light');
  }

  .pseudo-link {
    text-decoration: underline;
    @include applyTheme($themes) {
      color: themed('contrast-light');
    }
  }

  .add-file-icon {
    font-size: 40px;
  }

  .drag-container {
    background-color: transparent;
    height: 95px;
    position: relative;
    width: 100%;
  
    .drag-input {
      cursor: pointer;
      height: 100%;
      opacity: 0;
      width: 100%;
    }
  
    .drag-message-container {
      align-items: center;
      justify-content: space-between;
      display: flex;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  
    /** Drag Over Container Style **/
    .drag-message {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include applyTheme($themes) {
        background-color: themed('background-dark');
        color: themed('text-contrast');
      }
    }
  
    /** File Drop Area Styles **/
    .drag-target-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      background-color: transparent;
      height: 75px;
      width: 100%;
      @include applyTheme($themes) {
        color: themed('text-contrast');
      }

      .drag-target {
        flex-shrink: 0;
        height: 150px;
        width: 150px;
        margin-bottom: 20px;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 100px;
        font-weight: lighter;
        border: 1px dashed $light-gray;
        @include applyTheme($themes) {
          color: themed('text-contrast');
          border-color: themed('text-contrast');
        }
        &-message {
          text-align: center;
          line-height: 1.6;
        }
        &-or {
          padding: 5px 10px 10px 10px;
        }
      }
    }
  }
  
  &--disabled {
    @include applyTheme($themes) {
      border-color: themed('text-disabled');
      color: themed('text-disabled');

      .drag-input {
        cursor: not-allowed;
      }

      .pseudo-link {
        color: themed('text-disabled');
      }

      .drag-container .drag-message {
        color: themed('text-disabled');
      }
  
      .drag-container .drag-target-container {
        color: themed('text-disabled');
      }

      .drag-container .drag-target {
        color: themed('text-disabled');
        border-color: themed('text-disabled');
      }  
    }
  }
}
