@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.equipment-info-container {
  display: flex;
  flex-direction: row;
  padding: 15px 15px;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: $body-text;
  @include applyTheme($themes) {
    color: themed('text-main');
  }

  .left-panel {
    display: flex;
    text-align: center;
    width: 300px;
  }
  .right-panel {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    text-align: left;
    width: 540px;
    margin-right: 50px;
    font-size: $title-text;
    table {
      display: flex;
      flex-direction: column;
      border-collapse: collapse;
      margin: 10px 0;
    }

    table div th td {
      padding: 25px 0 0;
    }
    table div tr {
      display: flex;
      border-bottom: $light-gray 1px solid;
      padding: 5px 0;
      width: auto;
    }

    td {
      white-space: nowrap;
      padding: 5px;
    }

    .equipment-input-section {
      padding: 10px 0;
    }
    .label-caption{
      font-size: $caption-text;
      display: block;
      margin-top: 5px;
      width: 250px;
      max-width: 100%;
    }
    .coordinates-table {
      display: flex;
      justify-content: space-between;
      .label-section {
        display: flex;
        flex-direction: column;
        font-size: $title-text;
      }
    
      .table-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 255px;

        .row-inputs .number-input-group:first-of-type .number-input__label {
          margin-right: 20px;
        }

        .row-inputs .number-input-group:last-of-type {
          margin-left: 18px;
        }
      }
      .row-inputs {
        display: flex;
        justify-content: space-between;
        width: 250px;
        margin: 0;
        .coord-heading {
          font-weight: 500;
          display: flex;
          flex-grow: 1;
          justify-content: space-around;
          margin-left: 15px;
        }
        .number-input-group {
          .number-input-container {
            min-width: 75px;
          }

          .number-input__input {
            flex-grow: 0;
            text-align: center;
            width: 75px;
            min-width: 75px;
          }
          .number-input-unit {
            width: 32px;
          }
        }
      }
    }
    & .single-column{
      .table-section {
        max-width:225px;
      }
      .label-section {
        padding-top: 15px;
      }
    }
    .transformer-name {
      padding-bottom: 15px;
      width: 100%;
    }
    .end-info-panels {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .end-panel {
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
      }
    }
    .pf-tooltip {
      width: 575px;
    }
    .neutral-strand-resist {
      font-size: 17px;
    }
  }
  .large-labels{
    .number-input__label{
      min-width: 255px;
      max-width: 100%;
    }
  }
  .number-input-group,
  .text-input-group {
    min-height: 50px;
    justify-content: center;
     // Override to allow for validation message to appear outside
    // the normal flow (so inputs can be centered correctly
    .input-error {
      position: absolute;
      top: 45px;
      right: 0px;
    }
    sub {
      margin-right: 2px;
    }
  }

  .number-input-group {
    display: flex;
    flex-direction: column;
    position: relative;

    .number-input-container {
      display: flex;
      flex-grow: 1;
      max-width: 225px;
      min-width: 225px;
    }

    .number-input__input {
      display: flex;
      min-width: 225px;
      border-radius: 3px;
    }

    .number-input__label {
      font-size: $title-text;
    }

    .number-input-unit {
      background-color: transparent;
      justify-content: flex-start;
    }
  }
  .text-input-all .input-with-label { justify-content: space-between; }
  .text-input-group .text-input-container, .text-input-all .text-input__input {
      min-width: 225px;
      max-width: 225px;
  }
  .text-input-group, .text-input-all {
    display: flex;
    flex-direction: column;
    position: relative;

    .text-input__input {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .text-input__label {
      font-size: $title-text;
      width: 225px;
      color: inherit !important;
    }
  }

  .checkbox-row {
    display: flex;
    min-height: 50px;

    .checkbox {
      align-items: center;
      display: flex;
      text-align: left;
      width: 225px;
    }

    .checkbox-label {
      align-items: center;
      display: flex;
      flex: 1;
      font-size: $title-text;
      text-align: left;
    }
  }

  .select-section {
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    .select-row {
      display: flex;
      justify-content: space-between;
      height: 50px;
      .select {
        align-items: center;
        display: flex;
        text-align: left;
      }

      .select-label {
        display: flex;
        align-items: center;
        text-align: left;
        font-size: $title-text;
      }
    }
  }

  .custom-select-selected__btn:disabled {
    background-color: $light-gray;
    color: $dark-gray;
    cursor: not-allowed;
  }
}