@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import 'components/styles/_base.scss';

  .log {
    display: grid;
    grid-template-columns: auto auto auto auto;

    .header {
      width: 100%;
      font-weight: bold;
      
      @include applyTheme($themes) {
        border-color: themed('label-light');
      }
    }
    .downloadlink {
      height: auto;
    }

    > * {
      padding: 10px;
      border-bottom: 1px solid;
    }
  }
