@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.validate-network {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;

  @include applyTheme($themes) {
    color: themed('text-contrast');
  }

  .validation-tabs {
    height: 100%;

    .tab-container {
      width: 500px;
    }
  }

  .spinner {
    height: 150px;
    margin-top: 100px;
  }
}
