@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.voltage-histogram {
  &.card {
    min-height: 250px;
    margin: 0;
    display: flex;
    .content {
      height: 100%;
      width: 100%;
      min-height: 250px;
      min-width: 200px;
      .phase-selection-message {
        min-height: 16px;
      }

      .option-selector {
        max-width: 125px;
        margin: 5px 0;
      }

      .legend {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: $caption-text;
        margin-top: 15px;
      }
      .legend-entry {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        &.pu-distance-legend {
          color: #DE6E00;
          border-color: #DE6E00;
          .axis-box {
            border-bottom: 2px solid;
            height: 2px;
            width: 25px;
            margin-left: 5px;
            margin-right: 5px;
          }
        }
      }
    }

    .graph-placeholder {
      @extend .flex-centered;
      border-radius: 4px;
      height: 225px;
      margin: 10px 0;
      width: 100%;
      @include applyTheme($themes) {
        background-color: themed('background-light');
      }
      &.loading {
        background-color: transparent;
      }
    }

    .recharts-text tspan {
      @include applyTheme($themes) {
        fill: themed('text-main');
      }
    }

    .recharts-cartesian-axis-line,
    .recharts-cartesian-axis-tick-line {
      @include applyTheme($themes) {
        stroke: themed('text-main');
      }
    }

    .recharts-cartesian-grid-vertical line,
    .recharts-cartesian-grid-horizontal line {
      opacity: 0.4;
      @include applyTheme($themes) {
        stroke: themed('text-main');
      }
    }

    .recharts-rectangle.recharts-tooltip-cursor {
      fill-opacity: 0.5;
    }     

    .custom-tick {
      transform: rotate(-35deg);
      font-size: $caption-text;
      @include applyTheme($themes) {
        fill: themed('text-main');
      }
    }
    .custom-tooltip {
      padding: 10px;
      border: 1px solid black;
      background-color: rgba(0,0,0,0.5);
      font-size: $body-text;
  
      @include applyTheme($themes) {
        background-color: tryTheme('background-very-dark', rgba, 0.75);
        border-color: themed('background-very-dark');
        color: themed('text-main');
      }
    }
  }
}
  