@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.sharing-modal {

  .modal-message {
    padding: 0;
  }

  .modal-body {
    overflow: visible;
    width: 500px !important;
    z-index: 1001;
    max-height: 80%;
    overflow-y: auto;

    .header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      h2 {
        margin: 0;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      padding: 15px 0;

      .users-section {
        height: 100px;
        border: 1px solid $dark-gray;
        background-color: $dark-charcoal;
        width: 100%;
        padding-left: 10px;
        border-radius: 5px;
      }

      .input-row {
        display: flex;
        align-items: center;
        width: 100%;
        .input {
          display: flex;
          flex-direction: column;
          width: 50%;
          height: 85px;
          padding: 10px 0;
          &:first-of-type {
            padding-right: 20px;
          }
          &--permissions {
            padding: 8px 0;
          }
          .permissions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 3px;
            .help-icon {
              user-select: none;
              @include applyTheme($themes) {
                color: themed('text-main');
              }
            }
          }
        }
        .users-area {
          display: flex;
          flex-direction: column;
          width: 100%;
          .light {
            border: 1px solid #d4d4d4;
            background-color: #ffffff;
            .remove-user {
              border: none;
            }
            .user-row {
              border: 0px solid #ffffff;
              background: transparent;
            }
            .user-email {
              border: 0px solid #ffffff;
            }
          }
          .users {
            border-radius: 5px;
            min-width: 100%;
          }
        }
        .message-area {
          display: flex;
          flex-direction: column;
          width: 100%;
          .message {
            border-radius: 5px;
            min-width: 100%;
          }
        }
        .privacy-policy {
          @extend .flex-centered;
          font-size: $caption-text;
          padding: 10px 0;
          .privacy-link {
            font-size: $caption-text;
            color: $light-teal;
            &:hover {
              color: $medium-gray;
            }
          }
        }
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      align-items: center;
      height: 48px;
      .error-message {
        font-size: $caption-text;
        color: #D53831;
        white-space: pre-wrap;
        flex-grow: 2;
        line-height: 1.2;
      }
      .footer-button {
        margin-left: 10px;
      }
    }
  }
}

.light.message {
  border: 1px solid #d4d4d4;
  background-color: #ffffff;
}

.dark.message {
  border: 1px solid $dark-gray;
  background-color: $dark-charcoal;
  color: white;
}
