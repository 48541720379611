@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.number-input-group {
  margin: 3px 0;
  width: 100%;
  &.light {
    .number-input {
      &__input {
        background-color: transparent;
        border: 1px solid $light-gray;
        color: $dark-charcoal;
        &:disabled {
          background-color: $light-gray;
          color: $dark-gray;
        }
      }

      &-unit {
        background-color: rgba(209,209,209,0.6);
        color: $dark-gray;
      }
    }
  }
  &.dark {
    .number-input {
      &__input {
        background-color: transparent;
        border-color: $dark-gray;
        color: $white;
        &:disabled {
          background-color: $medium-charcoal;
        }
      }
      &-unit {
        background-color: $dark-gray;
        color: $light-gray;
      }
    }
  }

  &--invalid.dark .number-input__input,
  &--invalid.light .number-input__input {
    border-color: $warning-red;
  }

  .number-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    &__input {
      -moz-appearance: textfield;
      width: 120px;
      border: 1px solid $dark-gray;
      border-radius: 4px;
      padding: 2px 6px;
      font-size: $body-text;
      height: 32px;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &:disabled {
        background-color: $medium-charcoal;
        cursor: not-allowed;
        border: none;
        color: $very-lighter-gray;
      }
      &:hover, &:focus{
        @include applyTheme($themes) {
          border: 1px solid themed('contrast-light');
          outline: themed('contrast-light');
        }
      }
    }
    &__label {
      font-size: $body-text;
      color: inherit;
      margin-right: 40px;
    }

    &-container {
      display: flex;
    }

    &-with-unit {
      width: 75px;
      border-radius: 4px 0 0 4px;
    }

    &-unit {
      align-items: center;
      border-radius: 0 3px 3px 0;
      display: flex;
      font-size: $caption-text;
      height: 32px;
      justify-content: center;
      padding: 0 10px;
      width: 45px;
    }
  }
  .input-error {
    font-size: $caption-text;
    color: $warning-red;
    text-align: right;
    width: 100%;
    p {
      margin-left: 10px;
    }
  }
}
.number-input-group.non-form {
  .number-input-container {
    display: flex;
    width: 60%;
    justify-content: flex-start;
    padding: 0 30px;
  }
  .number-input {
    &__label {
      font-size: $body-text;
    }
    &__input {
      width: 120px;
      text-align: center;
    }
    &-unit {
      width: 80px;
    }
  }
}
