@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";

.equipment-info-selector {
  align-items: center;
  display: flex;
  margin-bottom: 6px;

  .tip-span {
    margin-left: 14px;
  }

  &-link {
    background-color: transparent;
    border: none;
    width: 24px;
    .st1 {
      @include applyTheme($themes) {
        fill: themed('text-main');
      }  
    }

    &:hover .st1 {
      fill: $light-teal;
      @include applyTheme($themes) {
        fill: themed('contrast-light');
      }
    }

    &--disabled,
    &--disabled:hover {
      cursor: not-allowed !important;
      .st1 {
        fill: $dark-gray;
        @include applyTheme($themes) {
          fill: themed('text-disabled');
        }  
      }
    } 
  }
  .custom-select__control {
    margin: 5px 0;
  }
}
