@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.node-display-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .swap-container {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;

        .display-row {
            display: flex;
            flex-shrink: 0;
            justify-content: space-between;
            align-items: flex-start;
            text-align: center;
            width: 235px;
            button {
                text-align: center;
                text-decoration: none;
                width: 95px;
            }
            .between-nodes {
                padding: 0 10px;
            }
            
            .node-display-container-node {
                border-bottom: 1px solid $light-teal;
            }
        }
    }

    .list-value-row-label {
        flex-shrink: 0;
        line-height: 24px;
        width: 75px;
    }

    .icon-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 50px;
    }

    &-icon {
        margin-left: 10px;
        margin-right: 10px; 
        width: 25px;
        height: 25px; 
    }

    &-node {
        margin-top: 2px;
        line-height: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 95px;
    }
}
