@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.status-bar-container {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.status-details {
  @extend .flex-centered;
  height: 15px;
}

.status-bar {
  transition: width 0.5s ease;
  height: 100%;
  position: relative;
  top: 0;
  z-index: 1;
  box-shadow: inset 0px -3px 5px rgba(90,90,90, 0.7);
}

/** Uploading Styles **/
.status-bar-percent-center {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 5;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.status-bar-percent-sides {
  display: inline-block;
  margin-left: 15px;
  font-size: $caption-text;
  flex-shrink: 0;
}

/** Error Style **/
.status-bar-error {
  font-weight: bold;
  color: #FFF;
  background-color: red;
  border-radius: 100px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/** Processing Style **/
.status-bar-stripes {
  right: 0;
  position: absolute;
  width: 2000px;
  height: 100%;
  background: $light-teal;
  background-image: $teal-stripes;
  background-size: 25px 10px;
  animation: slide 3s linear infinite;
  box-shadow: inset 0px -3px 4px rgba(0,0,0,0.3);
}

.status-bar-label {
  font-size: $caption-text;
}

@keyframes slide{
	from{right: 0;}
	to{right: -300px;}
}
