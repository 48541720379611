@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.container-panel {
  height: 100%;
  min-width: 375px;
  display: flex;
  flex-direction: column;
  width: 100%;
  .container-panel-header {
    padding: 0 20px;

    &--expanded {
      padding: 0 60px;
    }

    .header-btn {
      @extend .flex-centered;
      background-color: transparent;
      border: none;
      color: $medium-gray;
      cursor: pointer;
      flex-shrink: 0;
      font-size: $headline-text;
      height: 30px;
      width: 35px;
      line-height: normal;
      @include applyTheme($themes) {
        color: themed('text-main');
        .st1, #path_1_, #Rectangle_820, #ic_expand_close path {
          fill: themed('text-main')
        }

        &:hover {
          color: themed('contrast-light');
          .st1, #path_1_, #Rectangle_820, #ic_expand_close path {
            fill: themed('contrast-light')
          }
        }

        &:disabled,
        &:disabled:hover {
          color: themed('text-disabled');
          cursor: not-allowed;
          .st1, #path_1_, #Rectangle_820, #ic_expand_close path {
            fill: themed('text-disabled');
          }
        }  
      }

      path {
        opacity: .9;
      }

      &.clear-asset {
        padding-top: 5px;
      }
    }

    .edit-container-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > input {
        width: 75%;
      }

      span {
        @extend .flex-centered;       
      }

      .icon-btn,
      .icon-btn:hover {
        margin-left: 12px;
        background: transparent;
      }
    }
    .container-select-section { margin-top: 10px }
  }

  .feeder-content {
    display: flex;
    height: 100%;
    min-height: 0;

    .themed-tabs {
      width: 100%;
    }
  }

  .container-panel-tab-contents {
    padding: 10px 20px;
    &--expanded {
      padding: 10px 60px;
    }
  }

  .select-row {
    align-items: center;
    display: flex;
    padding: 5px 0 10px 0;
    width: 335px;

    label {
      font-size: $body-text;
      min-width: 120px;
      padding-right: 25px;
    }
  }

  .powerflow-btn-row,
  .cost-tracking-btn-row,
  .battery-sizing-btn-row,
  .hosting-capacity-btn-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 335px;

    .custom-btn {
      font-size: $body-text;
    }
  }
}
.feeder-tooltip {
  @extend .flex-centered;
  flex-direction: column;
  padding: 5px;
  &-header {
    color: $medium-teal !important;
    font-size: $body-text;
    margin-bottom: 5px;
  }
  &-contents {
    width: 100%;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
