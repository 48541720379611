@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
div.opf-options-item {
  display: flex;
  flex-direction: row;
  align-items: left;
  align-items: center;

  padding-bottom: 5px;

  * {
    display: inline;
  }
  .opf-options-item-label {
    padding-left: 5px;
  }
}
