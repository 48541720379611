@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.running-tooltip {
  border-radius: 3px;
  box-shadow: 0 11px 17px 5px rgba(0, 0, 0, 0.5);
  padding: 10px 24px;
  position: absolute;
  right: 5px;
  text-align: left;
  font-size: $caption-text;
  top: 110px;
  transition: max-height 0.3s ease, padding 0.2s ease;
  width: 200px;
  z-index: 1000;

  &.dark {
    background: $medium-charcoal;
    opacity: 1;
    color: $white;
    .close {
      &:hover {
        cursor: pointer;
        background-color: $panel-black;
      }
    }
  }
  &.light {
    background: $white;
    opacity: 1;
    color: #262626;
    .close {
      &:hover {
        cursor: pointer;
        background-color: #ededed;
      }
    }
  }

  .close {
    position: absolute;
    top: 2px;
    right: 2px;
    border-radius: 50%;
    font-size: $body-text;
  }

  .running-message {
    margin: 2px 0;
    line-height: 1.2em;
  }
}

.asset-panel-icons .validation {
  width: 24px;
  height: 24px;
  padding-top: 3px;
}


.icon-btn--active .icon-container .injected-svg {
  #network-error {
    @include applyTheme($themes) {
      fill: themed('contrast-light');
      color: themed('contrast-light');
    }
  }
}  
