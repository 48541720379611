@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.gis-top-nav {
  background-color: $dark-charcoal;
  border-bottom: 1px solid $panel-black;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
  @include applyTheme($themes) {
    background-color: themed('background-dark');
    border-color: themed('background-very-dark');
    color: themed('text-main');
  }

  .edit-mode-toggle {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 8px;
    .save-as-button {
      margin-right: 10px;
    }
    .expanded-menu {
      padding-bottom: 0;
    }
    .icon-btn, .icon-container{
      height: 35px;
    }
  }

  &--container {
    display: flex;
    justify-content: space-between;
    height: 75px;
    width: 100%;
    padding-right: 10px;
  }
  &--left {
    align-items: center;
    display: flex;
    padding: 5px 20px;    

    .view-selector-container {
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .select-container {
      color: inherit;
      width: 240px;
      padding-right: 20px;
      border-right: 1px solid;
      @include applyTheme($themes) {
        border-right-color: themed('background-very-light');
      }

      .view-top-row {
        display: flex;
        align-items: center;
        padding-bottom: 5px;
        height: 29px;
      }

      .select-label {
        font-size: $caption-text;
      }

      .Select-menu-outer {
        z-index: 10;
      }
      .select-dropdown-section { display: block }
    }

    .scenario-analysis {
      display: flex;
    }
  }

  .dropdown {
    display: flex;
    align-items: flex-end;
    margin-left: 10px;
  }

  .expanded-menu {
    display: flex;
    align-items: flex-end;
    border-radius: 0;
    padding-bottom: 7px;
    &.icon-btn-container,
    &.icon-btn-container .icon-btn {
      width: 25px;
    }
  }  

  .sliding-panel-tab {
    @extend .flex-centered;
    background-color: transparent;
    border-radius: 0;
    color: $medium-gray;
    fill: #959595;
    cursor: pointer;
    width: 50px;
    border: none;
    position: relative;
    margin-bottom: 5px;
    &:focus {
      @include applyTheme($themes) {
        outline-color: themed('contrast-light');
      }
    }

    &:hover {
      color: $light-gray;
      fill: #707070;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &--active,
    &--active:hover {
      @include applyTheme($themes) {
        fill: themed('contrast-light');
        color: themed('contrast-light');
      }
    }

    &.activity-log {
      position: relative;
    }
  }

  .light .sliding-panel-tab {
    .icon-container {
      fill: #959595;
    }
    &:hover {
      .icon-container {
        fill: #707070;
      }
    }
  }

  .dark .sliding-panel-tab {
    .icon-container {
      fill: #707070;
    }
    &:hover {
      .icon-container {
        fill: #959595;
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    .top-row-buttons {
      padding-left: 3px;
    }

    .dropdown:hover {
      .icon-container {
        background: transparent !important;
      }
      .light {
        .icon-btn .icon-container {
          color: #707070;
        }
      }
    }
  }
}
