@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.map-legend{
  @extend .flex-centered;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  z-index: 400;
  top: 1%;
  left: 35%;
  height: 30px;
  width: 200px;
  @include applyTheme($themes) {
    background-color: themed('background-light');
    border: 1px solid themed('background-very-dark');
    border-radius: 3px;
  }
  
  .indicator {
    @extend .flex-centered;
    .indicator-color > i {
      font-size: 18px;
      vertical-align: bottom;
    }
    .indicator-label{ 
      font-size: 14px;
      @include applyTheme($themes) {
        color: themed('text-contrast')
      }
    }
  }
}