@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.navigation-link {
  border: none;
  font-family: 'Roboto', sans-serif;
  font-size: $body-text;
  text-decoration: none;
  height: 35px;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0;
  flex-shrink: 0;
  width: 100%;
  .nav-icon-path {
    height: 35px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding-left: 15px;
    img {
      padding-top: 10px;
      padding-bottom: 10px;
      height: 100%;
      width: 100%;
    }
  }
  .nav-icon {
    height: 35px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 15px 30px;
    img {
      padding-top: 10px;
      padding-bottom: 10px;
      height: 100%;
      width: 100%;
    }
  }
  .nav-text {
    display: flex;
    align-items: center;
    padding: 15px 30px;
    height: 35px;
    width: 100%;
  }

  &.dark {
    background-color: $medium-charcoal;
    border-bottom: 1px solid $dark-charcoal;
    color: $white;
    &:hover {
      .nav-text {
        background-color: $dark-gray;
      }
      .nav-icon {
        background-color: $dark-gray;
      }
    }

    .nav-icon, .nav-icon-path {
      background-color: $medium-charcoal;
    }
  }

  &.light {
    color: $dark-gray;
    &:hover {
      .nav-text {
        background-color: #f6f6f6;
      }
      .nav-icon, .nav-icon-path {
        background-color: #f6f6f6;
      }
    }
  }
}
