@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.text-input-group {
  margin: 3px 0;
  .text-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    &__input {
      background-color: transparent;
      border: 1px solid $dark-gray;
      width: 100%;
      border-radius: 4px;
      padding: 2px 6px;
      font-size: $body-text;
      height: 32px;
      &.text-invalid {
        border-color: $warning-red;
      }
      &::placeholder {
        color: $medium-gray-90;
      }
    }
    &__label {
      font-size: $body-text;
      color: inherit;
      white-space: nowrap;
      margin-right: 40px;
    }

    &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &-with-unit {
      width: 85px;
      border-radius: 4px 0 0 4px;
    }
  }

  .text-invalid {
    border: 1px solid $warning-red !important;
  }

  .input-error {
    font-size: $caption-text;
    color: $warning-red;
    text-align: right;
    width: 100%;
  }

  &.dark {
    .text-input__input {
      border-color:  $dark-gray;
      color: $white;
      &:hover, &:focus {
        border: 1px solid $light-teal;
        outline: $light-teal;
      }
      &:disabled {
        background-color: $dark-gray;
        border-color:  $dark-gray;
        cursor: not-allowed;
        border: none;
        color: $very-lighter-gray;
      }
    }
    .text-optional{
      color: #909090;
      font-size: 12px;
    }
  }

  &.light {
    .text-input__input {
      border-color: $light-gray;
      color: $dark-gray;
      &:hover, &:focus {
        border: 1px solid $light-teal;
        outline: $light-teal;
      }
      &:disabled {
        background-color: $light-gray;
        color: $dark-gray;
        cursor: not-allowed;
        border: none;
      }
    }
    .text-optional{
      color: #909090;
      font-size: 12px;
    }

  }
}
