@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.branch-scenario-section, .analysis-scenario-section {
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .select-top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
    .select-label {
      @include applyTheme($themes) {
        color: themed('text-main');
      }
    }
  }

  .Select-clear-zone {
    display: flex;
    align-items: center;
  }

  .dropdown {
    .dropdown-content {
      z-index: 10;
      overflow: visible;
      height: auto !important;
    }

    .dropdown-item {
      overflow: visible;
      &:first-of-type {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
      &:last-of-type {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }

  .modal-body {
    z-index: 9000; // z-index less than tippy
  }

  .create-modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 105px;
    width: 100%;
    padding: 10px 0;

    label {
      line-height: 40px;
    }

    .name-input {
      background-color: $panel-black;
      border: 1px solid $dark-gray;
      border-radius: 4px;
      color: $light-gray;
      font-size: $title-text;
      height: 30px;
      padding: 3px 5px;
      width: 100%;
      @include applyTheme($themes) {
        background-color: themed('background-very-dark');
        border-color: themed('text-disabled');
        color: themed('text-contrast');
        &:invalid {
          border-color: $warning-red;
        }
      }
    }
    .upload-feeder-name {
      margin: 10px 0;
    }

    .file-form-btn,
    .file-form-input__label {
      align-items: center;
      display: flex;
      height: auto;
      justify-content: flex-start;
      width: auto;

      .custom-btn {
        pointer-events: none;
        margin-right: 12px;
        max-width: 200px;
      }
    }

    .scenario-upload-section {
      padding-top: 15px;

      h2 {
        line-height: 30px;
        margin: 0;
        padding-right: 8px;
      }

      &-header {
        .tip-span {
          height: 24px;
        }
      }
    }

   .feeder-file-upload {
     padding: 5px 0;
     overflow-x: hidden;
   }

   .feeder-file-upload-btn-label {
     max-width: 250px;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
   }
  }
}

.project-input {
  padding-top: 10px;
  &:first-of-type {
    height: 75px;
  }
}
.project-error-message, .input-error-message {
  color: $warning-red;
  display: flex;
  align-items: flex-end;
  min-height: 30px;
}
