@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.panel-header {
  display: flex;
  margin-bottom: 15px;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  justify-content: space-between;
  @include applyTheme($themes) {
    color: themed('text-main');
  }

  &__h3 {
    color: $light-teal;
    font-family: 'Roboto', sans-serif;
    font-weight: bolder;
    font-size: $title-text;
    margin: 0;
    @include applyTheme($themes) {
      color: themed('contrast-light');
    }
  }

  &__hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    margin: 0;
  }

  &-top {
    display: flex;
    justify-content: space-between;

    .close-btn {
      background-color: $dark-charcoal;
      border: none;
      cursor: pointer;
      color: $light-gray;
      width: 30px;
      height: 30px;
      outline: none;
      fill: $light-gray;
      &:focus, &:hover {
        color: $light-teal;
      }
    }
  }

  .svg-icon {
    svg {
      height: 35px;
      width: 35px;
    }

    g path,
    g circle {
      @include applyTheme($themes) {
        fill: themed('text-contrast');
      }
    }
  }

  #substation_icon {
    .asset-main{
      fill: $light-teal;
    }
    .asset-secondary{
      @include applyTheme($themes) {
        fill: themed('background-dark');
      }
    }
  }

  .panel-info {
    &__p {
      font-size: $caption-text;
      padding: 3px 0;
      width: 250px;
      @include applyTheme($themes) {
        color: themed('text-contrast');
      }
    }
    &-container {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
    }
  }

  .panel-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $body-text;
    width: 100%;
  }
  
  .panel-icon {
    width: 35px;
    height: 35px;
    &-container {
      @extend .flex-centered;
      width: 30px;
      height: 37px;
      margin-right: 20px;
      .svg-icon,
      .svg-icon div {
        @extend .flex-centered;
        height: 37px;
      }
    }
  }
  .panel-header-container {
    display: flex;
    height: 75px;
    align-items: center;
  }
}

.panel-header.support-tabs {
  margin-bottom: 0;

  .panel-header__hr {
    border: none;
  }
}
