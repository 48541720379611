@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.time-series-chart {
  margin: 20px 0;
  @include applyTheme($themes) {
    color: themed('text-main');
  }
  .content {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  
    h4 {
      margin: 7px 0;
      align-self: center;
      font-weight: 400;
      font-size: $body-text;
      text-transform: capitalize;
    }

    .unit-label-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      color: $medium-gray;
      font-size: $caption-text;
      width: 25%;
      margin-top: 20px;
    }

    .custom-inline-select {
      width: 60px;
      font-size: $caption-text;
      border-radius: 0;

      .Select-control {
        border-top: none;
        border-bottom: none;
      }

      .Select-value-label::first-line	{
        color: $medium-gray;
      }
    }

    .chart-pane {
      height: 150px;
      margin-right: 15px;
      &--expanded {
        height: 250px;
      }
    }

    .graph-placeholder {
      @extend .flex-centered;
      width: 100%;
      min-height: 180px;
    }

    .legend {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: $caption-text;
    }

    .legend-entry {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: flex-start;
    }

    .left-axis-box {
      border-top: 2px dashed;
      height: 10px;
      width: 25px;
      margin-top: 5px;
      margin-left: 5px;
      margin-right: 5px;
    }

    .right-axis-box {
      height: 2px;
      width: 25px;
      margin-top: 5px;
      margin-left: 5px;
      margin-right: 5px;
    }
 
    .axis-box-solid {
      border-top: 2px solid;
    }

    .unbalanced-legend {
      background: linear-gradient(to right, #639DD1 , #2dafa8, #fd813b);
      border: none;
    }

    .recharts-text {
      font-size: $body-text;
    }

    .tooltip {
      padding: 10px;
      border: 1px solid black;
      background-color: rgba(0,0,0,0.5);
      font-size: $body-text;
  
      @include applyTheme($themes) {
        background-color: tryTheme('background-very-dark', rgba, 0.75);
        border-color: themed('background-very-dark');
        color: themed('text-main');
      }

      th {
        border-bottom: 1px solid rgba(255,255,255,0.5);
      }
    }
  }
}
