@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.confirm-delete-container {
  .modal-header {
    height: auto;
  }
  .confirm-delete-footer {
    .button-div {
      display: inline-block;
      width: 50%;

      .confirm-delete-button {
        background-color: $medium-teal;
        color: $white;
        margin: 0px;
        padding: 5px 15px;
        &:hover {
          background-color: $light-teal;
          color: $white;
        }
      }
      

      .cancel-delete-button {
        background-color: $dark-gray;
        color: $white;

        margin: 0px;
        padding: 5px 15px;
        &:hover {
          background-color: #A0A0A0;
          color: $white;
        }
      }
      
    }
  }
}


