@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import 'skeleton-screen-css/dist/index.scss';
@import '../styles/base';
@import '../styles/_theme.scss';

.loading-skeleton {
    display: block;
    width: 100%;
    .ssc-line, .ssc-circle, .ssc-square, .ssc-head-line {
        @include applyTheme($themes) {
            background-color: themed('skeleton-light');
        }
    }
}
.light {
    .ssc-circle:after, .ssc-line:after, .ssc-head-line:after, .ssc-square:after {
        background: linear-gradient(90deg, transparent, rgba(204, 204, 204, 0.3), transparent)
    }
}
