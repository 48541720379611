@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.icon-btn-container {
  .icon-btn {
    @extend .flex-centered;
    cursor: pointer;
    height: 24px;
    width: 24px;
    padding: 0;

    &.custom-btn.light,
    &.custom-btn.dark {
      background-color: transparent;
    }

    &.round {
      border-radius: 50%;
    }

    .icon-container {
      @extend .flex-centered;
      height: 24px;
      width: 24px;
      font-size: $icon-size;
      .material-icons {
        font-size: inherit;
      }
    }

    &:disabled {
      cursor: not-allowed;

      .icon-btn-image {
        background-color: transparent;
      }
    }
  }

  &.dark {
    .icon-container {
      color: #707070;
    }

    .custom-btn.icon-btn:hover {
      background-color: #343434;

      .icon-container {
        color: #959595;
      }
    }

    .custom-btn.icon-btn:disabled,
    .custom-btn.icon-btn:disabled:hover {
      cursor: not-allowed;
      background-color: transparent !important;
      .icon-container {
        background-color: transparent !important;
        color: #444444 !important;
      }
    }

    .custom-btn.icon-btn--active .icon-container,
    .custom-btn.icon-btn--active:hover .icon-container {
      & > i {
        color: #00827b;
      }

      & svg {
        fill: #00827b;
      }
    }
  }

  &.light {
    .icon-container {
      color: #959595;
    }

    .custom-btn.icon-btn:hover {
      background-color: #f5f5f5;

      .icon-container {
        color: #707070;
      }
    }

    .custom-btn.icon-btn:disabled,
    .custom-btn.icon-btn:disabled:hover {
      background-color: transparent;
      .icon-container {
        color: #e2e2e2;
      }
    }

    .custom-btn.icon-btn--active .icon-container,
    .custom-btn.icon-btn--active:hover .icon-container {
      & > i {
        color: #00b4aa;
      }

      & svg {
        fill: #00b4aa;
      }
    }
  }
}
