@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.overview-with-nav {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  @include applyTheme($themes) {
    background: themed('background-main-new');
    
  }
  .gis-top-nav {
    @include applyTheme($themes) {
      background: themed('contrast-neutral-new');
    }
  }
  .workspace-overview {
    display: flex;
    flex-direction: column;
    padding: 50px;
    font-size: $small-header;
    .page-header-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .refresh-button {
        border-radius: 4px;
        font-weight: bold;
        width: 150px;
        @include applyTheme($themes) {
          box-shadow: 2px 2px 6px 0 themed('button-shadow-new');
          background: themed('contrast-neutral-new');
          color: themed('secondary-button-text-new');
        }
        &:hover {
          border-radius: 4px;
          font-weight: bold;
          @include applyTheme($themes) {
            box-shadow: 2px 2px 6px 0 themed('button-shadow-new');
            background: themed('contrast-neutral-new');
            color: themed('secondary-button-text-new');
          }
        }
      }
    }
    .header-row {
      width: calc(90% - 50px);
      .header {
        font-size: $caption-text;
        @include applyTheme($themes) {
          color: themed('text-header-new');
        }
        #calendar-svg {
          @include applyTheme($themes) {
            fill: themed('text-header-new');
          }
        }
      }
    }
    .version-row {
      padding: 2px;
      background: transparent;
      .version-expandable-header {
        border-radius: 5px;
        @include applyTheme($themes) {
          background: themed('contrast-neutral-new');
        }
        &.expandable-section .header {
          align-items: center;
          @include applyTheme($themes) {
            color: themed('text-new');
          }
          .grid-layout {
            padding: 5px 10px;
            .workspace-name {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .dot {
                height: 4px;
                width: 4px;
                border-radius: 50%;
                display: inline-block;
                margin-right: 10px;
                @include applyTheme($themes) {
                  background: themed('text-header-new');
                }
              }
            }
          }
          .toggle-content {
            height: auto;
          }
          &:hover {
            @include applyTheme($themes) {
              color: themed('text-new');
            }
          }
        }
      }
      .expandable-section .content-container--active {
        @include applyTheme($themes) {
          background: themed('contrast-neutral-new');
        }
        .scenario-row {
          width: 100%;
          @include applyTheme($themes) {
            color: themed('text-header-new');
          }
          #calendar-svg {
            @include applyTheme($themes) {
              fill: themed('text-header-new');
            }
          }
          .scenario-section {
            width: calc(90% - 40px);
          }
        }
        .scenario-info {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .multibadge-container {
            margin-left: 0;
            .select-badge-badge {
              @include applyTheme($themes) {
                color: themed('text-header-new');
                border-color: themed('text-header-new');
              }
            }
          }
          .scenario-name {
            padding-left: 10px;
            @include applyTheme($themes) {
              color: themed('text-new');
            }
          }
          .scenario-dates {
            margin-right: 10px;
            width: 150px;
            &:first-of-type {
              width: 23px;
              #calendar-svg {
                @include applyTheme($themes) {
                  fill: themed('text-new');
                }
              }
            }
            &-empty {
              width: 150px;
              @include applyTheme($themes) {
                color: themed('text-header-new');
              }
            }
            &.badge-empty {
              #calendar-svg {
                @include applyTheme($themes) {
                  fill: themed('text-header-new');
                }
              }
            }
            &.date-badge {
              padding: 5px;
              text-align: center;
              border-radius: 12px;
              margin-left: -3px;
              @include applyTheme($themes) {
                background: themed('background-main-new');
                color: themed('text-new');
              }
            }
          }
          &:first-of-type {
            width: 185px;
          }
        }
        .no-scenarios {
          padding-left: 15px;
          font-style: italic;
          @include applyTheme($themes) {
            color: themed('text-header-new');
          }
        }
      }
    }
    .loading-skeleton-new-theme {
      .ssc-line, .ssc-circle, .ssc-square, .ssc-head-line {
        @include applyTheme($themes) {
            background-color: themed('skeleton-light-new');
        }
      }
    }
  }
}
