@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.generation-card {
  padding: 10px;

  .card {
    padding: 0px;
    margin: 0;
  }

  .card .content {
    padding: 0;
    margin: 0;
  }

  .load-card__btn-group {
    padding: 0;
    margin: 0;
  }

  .load-card__graph {
    padding: 0 !important;
    margin: 0;
  }

  .screen-btn {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    @include applyTheme($themes) {
      color: themed('text-contrast');
    }
  }
  
  .legend {
    padding-left: 2px;
  }

  .unit-label-row {
    padding-left: 5px;
  }
}