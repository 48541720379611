@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

/**
 * Single Element CSS Spinners.
 * License Here: https://github.com/lukehaas/css-loaders/blob/step2/LICENSE
 */

/* Circles Loading Spinner Styles */
.loading-spinner {
  &--circles {
    font-size: 25px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.5s infinite ease;
    animation: load5 1.5s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  @-webkit-keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em $white, 1.8em -1.8em 0 0em $white-20, 2.5em 0em 0 0em $white-20, 1.75em 1.75em 0 0em $white-20, 0em 2.5em 0 0em $white-20, -1.8em 1.8em 0 0em $white-20, -2.6em 0em 0 0em $white-50, -1.8em -1.8em 0 0em $white-70;
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em $white-70, 1.8em -1.8em 0 0em $white, 2.5em 0em 0 0em $white-20, 1.75em 1.75em 0 0em $white-20, 0em 2.5em 0 0em $white-20, -1.8em 1.8em 0 0em $white-20, -2.6em 0em 0 0em $white-20, -1.8em -1.8em 0 0em $white-50;
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em $white-50, 1.8em -1.8em 0 0em $white-70, 2.5em 0em 0 0em $white, 1.75em 1.75em 0 0em $white-20, 0em 2.5em 0 0em $white-20, -1.8em 1.8em 0 0em $white-20, -2.6em 0em 0 0em $white-20, -1.8em -1.8em 0 0em $white-20;
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em $white-20, 1.8em -1.8em 0 0em $white-50, 2.5em 0em 0 0em $white-70, 1.75em 1.75em 0 0em $white, 0em 2.5em 0 0em $white-20, -1.8em 1.8em 0 0em $white-20, -2.6em 0em 0 0em $white-20, -1.8em -1.8em 0 0em $white-20;
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em $white-20, 1.8em -1.8em 0 0em $white-20, 2.5em 0em 0 0em $white-50, 1.75em 1.75em 0 0em $white-70, 0em 2.5em 0 0em $white, -1.8em 1.8em 0 0em $white-20, -2.6em 0em 0 0em $white-20, -1.8em -1.8em 0 0em $white-20;
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em $white-20, 1.8em -1.8em 0 0em $white-20, 2.5em 0em 0 0em $white-20, 1.75em 1.75em 0 0em $white-50, 0em 2.5em 0 0em $white-70, -1.8em 1.8em 0 0em $white, -2.6em 0em 0 0em $white-20, -1.8em -1.8em 0 0em $white-20;
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em $white-20, 1.8em -1.8em 0 0em $white-20, 2.5em 0em 0 0em $white-20, 1.75em 1.75em 0 0em $white-20, 0em 2.5em 0 0em $white-50, -1.8em 1.8em 0 0em $white-70, -2.6em 0em 0 0em $white, -1.8em -1.8em 0 0em $white-20;
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em $white-20, 1.8em -1.8em 0 0em $white-20, 2.5em 0em 0 0em $white-20, 1.75em 1.75em 0 0em $white-20, 0em 2.5em 0 0em $white-20, -1.8em 1.8em 0 0em $white-50, -2.6em 0em 0 0em $white-70, -1.8em -1.8em 0 0em $white;
    }
  }
  @keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em $white, 1.8em -1.8em 0 0em $white-20, 2.5em 0em 0 0em $white-20, 1.75em 1.75em 0 0em $white-20, 0em 2.5em 0 0em $white-20, -1.8em 1.8em 0 0em $white-20, -2.6em 0em 0 0em $white-50, -1.8em -1.8em 0 0em $white-70;
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em $white-70, 1.8em -1.8em 0 0em $white, 2.5em 0em 0 0em $white-20, 1.75em 1.75em 0 0em $white-20, 0em 2.5em 0 0em $white-20, -1.8em 1.8em 0 0em $white-20, -2.6em 0em 0 0em $white-20, -1.8em -1.8em 0 0em $white-50;
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em $white-50, 1.8em -1.8em 0 0em $white-70, 2.5em 0em 0 0em $white, 1.75em 1.75em 0 0em $white-20, 0em 2.5em 0 0em $white-20, -1.8em 1.8em 0 0em $white-20, -2.6em 0em 0 0em $white-20, -1.8em -1.8em 0 0em $white-20;
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em $white-20, 1.8em -1.8em 0 0em $white-50, 2.5em 0em 0 0em $white-70, 1.75em 1.75em 0 0em $white, 0em 2.5em 0 0em $white-20, -1.8em 1.8em 0 0em $white-20, -2.6em 0em 0 0em $white-20, -1.8em -1.8em 0 0em $white-20;
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em $white-20, 1.8em -1.8em 0 0em $white-20, 2.5em 0em 0 0em $white-50, 1.75em 1.75em 0 0em $white-70, 0em 2.5em 0 0em $white, -1.8em 1.8em 0 0em $white-20, -2.6em 0em 0 0em $white-20, -1.8em -1.8em 0 0em $white-20;
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em $white-20, 1.8em -1.8em 0 0em $white-20, 2.5em 0em 0 0em $white-20, 1.75em 1.75em 0 0em $white-50, 0em 2.5em 0 0em $white-70, -1.8em 1.8em 0 0em $white, -2.6em 0em 0 0em $white-20, -1.8em -1.8em 0 0em $white-20;
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em $white-20, 1.8em -1.8em 0 0em $white-20, 2.5em 0em 0 0em $white-20, 1.75em 1.75em 0 0em $white-20, 0em 2.5em 0 0em $white-50, -1.8em 1.8em 0 0em $white-70, -2.6em 0em 0 0em $white, -1.8em -1.8em 0 0em $white-20;
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em $white-20, 1.8em -1.8em 0 0em $white-20, 2.5em 0em 0 0em $white-20, 1.75em 1.75em 0 0em $white-20, 0em 2.5em 0 0em $white-20, -1.8em 1.8em 0 0em $white-50, -2.6em 0em 0 0em $white-70, -1.8em -1.8em 0 0em $white;
    }
  }

  /* Bars Loading Spinner Styles */
  &--bars,
  &--bars:before,
  &--bars:after {
    background: $white;
    -webkit-animation: load1 1.5s infinite ease-in-out;
    animation: load1 1.5s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }
  &--bars {
    color: $white;
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  &--bars:before,
  &--bars:after {
    position: absolute;
    top: 0;
    content: '';
  }
  &--bars:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  &--bars:after {
    left: 1.5em;
  }
  @-webkit-keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
}
