@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.calendar {
  width: 100%;
  height: 100%;
  font-size: $body-text;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  @include applyTheme($themes) {
    color: themed('text-main');
  }
  &-button {
    outline-width: 0;
  }
  .arrow {
    font-size: 1.25em;
    @include applyTheme($themes) {
      color: themed('text-main');
    }
    &:focus {
      outline-width: 0;
    }
    &:hover {
      @include applyTheme($themes) {
        background: themed('background-dark');
      }
    }
  }
  .calendar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 38px;
    .header-title {
      font-weight: 500;
      font-size: 1.25em;
      text-align: center;
      padding: 0.5em 0.25em;
      word-spacing: 5px;
      user-select: none;
      @include applyTheme($themes) {
        color: themed('text-main');
      }
    }
  }
  .calendar-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 213px;
    margin-top: 10px;
    @include applyTheme($themes) {
      color: themed('text-main');
    }
    .calendar-day {
      display: flex;
      justify-content: center;
      font-weight: 600;
      font-size: $caption-text;
      width: calc(100% / 7.001); // fp errors mess up IE with 7.0
      border-top: none;
      border-bottom: none;
      border-right: none;
      @include applyTheme($themes) {
        color: themed('text-main');
      }
      &--end {
        border-right: none;
      }
    }
    .regular-date {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      font-weight: 300;
      font-size: $body-text;
      border: none;
      transition: all .4s ease-out;
      min-width: calc(100% / 7.001); // fp errors mess up IE with 7.0
      height: 33px;
      pointer-events: none;
      @include applyTheme($themes) {
        color: themed('background-dark');
      }
      &--inMonth {
        font-weight: 500;
        cursor: pointer;
        pointer-events: auto;
        @include applyTheme($themes) {
          color: themed('text-main');
        }
        &--right {
          border-right: 1px solid transparent;
          @include applyTheme($themes) {
            border-right-color: themed('background-light');
          }
        }
      }
      &--bottom {
        border-bottom: 1px solid transparent;
        @include applyTheme($themes) {
          border-bottom-color: themed('background-light');
        }
      }
      &--left {
        border-left: 1px solid transparent;
        @include applyTheme($themes) {
          border-left-color: themed('background-light');
        }
      }
      &--top {
        border-top: 1px solid transparent;
        @include applyTheme($themes) {
          border-top-color: themed('background-light');
        }
      }
    }
    .regular-month, .regular-year {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      font-size: $body-text;
      border: none;
      transition: all .4s ease-out;
      min-width: calc(100% / 4.001); // fp errors mess up IE with 4.0
      height: 66px;
      font-weight: 500;
      cursor: pointer;
      pointer-events: auto;
      border: 1px solid transparent;
      @include applyTheme($themes) {
        border-left-color: themed('background-light');
        border-top-color: themed('background-light');
      }
      &--right {
        @include applyTheme($themes) {
          border-right-color: themed('background-light');
        }
      }
      &--bottom {
        @include applyTheme($themes) {
          border-bottom-color: themed('background-light');
        }
      }
    }
    .regular-hour {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      font-size: $body-text;
      border: none;
      transition: all .4s ease-out;
      min-width: calc(100% / 6.001); // fp errors mess up IE with 4.0
      height: 53px;
      font-weight: 500;
      cursor: pointer;
      pointer-events: auto;
      border: 1px solid transparent;
      @include applyTheme($themes) {
        border-left-color: themed('background-light');
        border-top-color: themed('background-light');
      }
      &--right {
        @include applyTheme($themes) {
          border-right-color: themed('background-light');
        }
      }
      &--bottom {
        @include applyTheme($themes) {
          border-bottom-color: themed('background-light');
        }
      }
    }
    .regular-minute {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      font-size: $body-text;
      border: none;
      transition: all .4s ease-out;
      min-width: calc(100% / 10.001); // fp errors mess up IE with 4.0
      height: 35px;
      font-weight: 500;
      cursor: pointer;
      pointer-events: auto;
      border: 1px solid transparent;
      @include applyTheme($themes) {
        color: themed('text-contrast');
        border-left-color: themed('background-light');
        border-top-color: themed('background-light');
      }
      &--right {
        @include applyTheme($themes) {
          border-right-color: themed('background-light');
        }
      }
      &--bottom {
        @include applyTheme($themes) {
          border-bottom-color: themed('background-light');
        }
      }
      &--disabled {
        @include applyTheme($themes) {
          color: themed('text-disabled') !important;
        }
      }
    }
    .todays-date {
      position: relative;
      @include applyTheme($themes) {
        color: themed('contrast-light');
      }
      background: transparent !important;
    }
    .highlighted-date {
      color: #fff !important;
      position: relative;
      @include applyTheme($themes) {
        background-color: themed('contrast-light') !important;
      }
      &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
      }
      &--bounding {
        color: white !important;
        @include applyTheme($themes) {
          background-color: themed('contrast-dark') !important;
        }
      }
    }
  }

  .analysis {
    background: #9E55F1;
  }
  .scenario {
    background: rgb(243, 220, 55);
  }
  .violations {
    background: $warning-red;
  }

  .calendar-legend {
    display: flex;
    font-size: $caption-text;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    span {
      margin-right: 8px;
    }

    .calendar-dot {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-right: 4px;
    }
  }
  .calendar-dot-container, .calendar-alignment {
    height: 8px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    .calendar-dot {
      width: 6px;
      height: 6px;
      border-radius: 3px;
      margin-left: 5%;
      margin-right: 5%;
    }
  }
  &.style-for-topnav {
    .regular-date,
    .regular-month,
    .regular-year,
    .regular-hour {
      font-weight: normal;
      font-size: 12px;
    }
  }
}
