@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.text-input-all {
  margin: 3px 0;
  display: flex;
  width: 100%;
  .text-input {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    width: 100%;
    .input-with-label {
      display: flex;
      justify-content: space-between;
      width: 100%;
      &-modern {
        flex-direction: column;
      }
    }
    &__input {
      background-color: transparent;
      border: 1px solid $dark-gray;
      border-radius: 4px;
      padding: 2px 6px;
      font-size: $body-text;
      height: 32px;
      width: 100%;
      &-modern {
        font-size: $caption-text;
      }
      &.text-invalid {
        border-color: $warning-red;
      }
      &::placeholder {
        color: $medium-gray-90;
      }

    }
    &__label {
      display: flex;
      align-items: center;
      font-size: inherit;
      white-space: nowrap;
      margin-right: 40px;
      &-modern {
        color: #95A6B8;
        font-size: 10px;
      }
    }

    &-container {
      display: flex;
      width: 100%;
      
      .unit {
        display: flex;
        align-items: center;
        padding-left: 5px;
        &-modern {
          color: #95A6B8;
          font-size: $caption-text;
          align-items: flex-end;
          padding-bottom: 8px;
        }
      }
    }
  }

  .text-invalid {
    border: 1px solid $warning-red !important;
  }

  .input-error {
    font-size: $caption-text;
    color: $warning-red;
    text-align: right;
    width: 100%;
  }

  &.dark {
    .text-input__input {
      border-color:  $dark-gray;
      color: $white;
      &:hover, &:focus {
        border: 1px solid $light-teal;
        outline: $light-teal;
      }
      &:disabled {
        background-color: $dark-gray;
        border-color:  $dark-gray;
        cursor: not-allowed;
        border: none;
        color: $very-lighter-gray;
      }
    }
    .text-input__label {
      color: $white;
    }
    .text-optional{
      color: #909090;
      font-size: 12px;
    }
    .unit {
      color: $white;
    }
  }

  &.light {
    .text-input__input {
      border-color: $light-gray;
      color: $dark-gray;
      &:hover, &:focus {
        border: 1px solid $light-teal;
        outline: $light-teal;
      }
      &:disabled {
        background-color: $light-gray;
        color: $dark-gray;
        cursor: not-allowed;
        border: none;
      }
    }
    .text-optional{
      color: #909090;
      font-size: 12px;
    }
  }

  &.modern {
    .text-input__input {
      border: none;
      outline: none;
      color: black;
      background-color: #ECF3FA;
    }
  }
}