@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.error-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .selector-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .error-page-header {
    align-items: center;
    color: $white;
    display: flex;
    flex-shrink: 0;
    height: 65px;
    justify-content: space-between;
    padding: 5px 10px;
    width: 100%;

    h2 {
      margin: 0;
    }
  }

  .error-page-select {
    display: flex;
    width: 200px;
    flex-direction: column;
  }

  .error-table-container {
    height: 100%;
    min-height: 0;
    display: flex;
    width: 100%;
  }
}

.profile-error {
  &__option {
    text-transform: capitalize;
  }
  &__select {
    text-transform: capitalize;
    padding: 5px;
    padding-right: 10px;
    padding: 5px 8px;
    border: none;
    box-shadow: none;
    background-image: none;
    @include applyTheme($themes) {
      background-color: themed('background-dark');
      color: themed('text-contrast');
    }
  }
  &__label {
    margin-right: 10px;
  }
}
