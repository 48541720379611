@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.light .toggle-container {
  display: flex;
  justify-content: space-between;
  background-color: $light-gray !important;
  padding: 3px;
  border-radius: 4px;
  .left-toggle--primary {
    background-color: $white !important;
    color: $dark-charcoal;
    border: none;
    box-shadow: 2px 1px 4px 0 rgba(160,160,160,0.66);
    &:hover {
      background-color: $white;
      color: $dark-charcoal;
    }
  }
  .left-toggle--secondary {
    background-color: $light-gray !important;
    border: none;
    &:hover {
      background-color: $light-gray;
    }
  }
  
  .right-toggle--primary {
    background-color: $white !important;
    color: $dark-charcoal;
    border: none;
    box-shadow: -2px 1px 4px 0 rgba(160,160,160,0.66);
    &:hover {
      background-color: $white;
      color: $dark-charcoal;
    }
  }
  .right-toggle--secondary {
    background-color: $light-gray !important;
    border: none;
    &:hover {
      background-color: $light-gray;
    }
  }
}
.dark .toggle-container {
  display: flex;
  justify-content: space-between;
  background-color: $darker-gray !important;
  padding: 3px;
  border-radius: 4px;
  .left-toggle--primary {
    color: $white;
    background-color: $medium-gray;
    border: none;
    &:hover {
      background-color: $medium-gray;
    }
  }
  .left-toggle--secondary {
    color: $white;
    background-color: $darker-gray;
    border: none;
    &:hover {
      background-color: $darker-gray;
      color: $white;
    }
    
  }
  .right-toggle--primary {
    color: $white;
    background-color: $medium-gray;
    border: none;
    &:hover {
      background-color: $medium-gray;
    }
  }
  .right-toggle--secondary {
    color: $white;
    background-color: $darker-gray;
    border: none;
    &:hover {
      background-color: $darker-gray;
      color: $white;
    }
  }
}
