@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.expandable-section {
  display: flex;
  width: 100%;
  flex-direction: column;

  .header {
    -webkit-appearance: none;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    padding: 0;
    background-color: transparent;
    border: none;
    &.clickable {
      cursor: pointer;
    }
    &:hover {
      color: $light-teal;
    }
    .toggle {
      @extend .flex-centered;
      width: 100%;
      background-color: transparent;
      border: none;
      text-align: center;
      &:hover i {
        color: inherit;
      }
    }
    &:disabled {
      color: $medium-gray;
      cursor: not-allowed;
    }
  }

  .header-content {
    width: 90%;
    text-align: left;
    line-height: 24px;
  }

  .toggle-content {
    user-select: none;
    width: 24px;
    height: 24px;
    margin-left: 8px;
    background: transparent;
    color: inherit;
    border: none;
  }

  
  .content-container {
    height: auto;
    max-height: 1px;
    min-height: 1px;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    transition: 1s ease max-height, visibility 0s linear 0.5s, opacity 0.5s;
    &--active {
      min-height: 2px;
      max-height: 4000px;
      height: auto;
      opacity: 1;
      visibility: visible;
      overflow: visible;
      width: 100%;
    }
  }

  .content {
    padding: 5px 10px;
    &--scroll {
      max-height: 245px;
      overflow-y: auto;
    }
  }
}

.expandable-section.disable-animation {
  .content-container {
    transition: none !important;
  }
}
