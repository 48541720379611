@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.themed-tabs {
  display: flex;
  flex-direction: column;
  width: 100%;

  .tab-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .react-tabs__tab-panel {
    min-height: 0;
    &--selected {
      display: flex;
      flex: 1;
      height: 100%;
      min-height: 0;
      @include applyTheme($themes) {
        color: themed('text-main');
      }
    }
  }

  .react-tabs__tab-list {
    margin-top: 0;
    padding-left: 0;
    margin-bottom: 5px;
    list-style: none;
    display: flex;
    flex-shrink: 0;
    height: 48px;
    min-height: 0;
    border-bottom: 1px solid transparent;
  }

  .react-tabs__tab {
    min-width: 90px;
    min-height: 48px;
    flex-grow: 1;
    text-align: center;
    cursor: pointer;
    padding: 10px 16px 12px 16px;
    color: $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid transparent;
    @include applyTheme($themes) {
      color: themed('text-main');
    }
    &--selected,
    &:focus {
      color: $light-teal;
      border: 3px $light-teal;
      border-style: none none solid none;
      background: transparent;
      padding: 10px 16px;
      outline: none;
      border-radius: 0;
      box-shadow: none;
      @include applyTheme($themes) {
        color: themed('contrast-light');
        border-color: themed('contrast-light');
      }
      &::after {
        border: none;
        outline: none;
        background: none;
      }
    }
    &:hover {
      @include applyTheme($themes) {
        background-color: themed('background-neutral');
      }
    }
    &--disabled {
      cursor: not-allowed;
      &:hover {
        .permission-disabled-tip .tooltip .tooltip-contents {
          visibility: visible;
        }
      }
    }

    .tip-span {
      @extend .flex-centered;
      height: 100%;
      width: 100%;
    }
  }

  

&.light {
  .react-tabs__tab {
    font-weight: 400;
  }
  .react-tabs__tab--selected {
    font-weight: 500;
  }
  &.shadow {
    .react-tabs__tab-list {
      box-shadow: 0 4px 8px 0 rgba(0,0,0,.15), 0 19px 38px 0 rgba(0,0,0,.05);
    }
  }
  &.line {
    .react-tabs__tab-list {
      box-shadow: none;
      border-bottom: 1px solid #ececec;
    }
  }
}

&.dark {
  .react-tabs__tab,
  .react-tabs__tab--selected {
    font-weight: 400;
  }
  &.shadow {
    .react-tabs__tab-list {
      box-shadow: 0 4px 8px 0 rgba(0,0,0,.5), 0 19px 38px 0 rgba(0,0,0,.3);
    }
  }
  &.line {
    .react-tabs__tab-list {
      box-shadow: none;
      border-bottom: 1px solid #3b3b3b;
    }
  }
}
  
}

