@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


/** WorkspaceDetails styles **/
.workspace-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 375px;
  width: 100%;
  padding: 20px;
  &--expanded {
    padding: 20px 60px;
  }

  .panel-contents {
    display: flex;
    flex-direction: column;
  }

  .der-scenario {
    .header {
      height: 34px;
    }

    .content {
      padding-top: 15px;
    }
  }

  .workspace-link {
    display: flex;
    align-items: center;
    height: 36px;
    text-decoration: none;
    font-size: $title-text;
    padding-left: 0;
    @include applyTheme($themes) {
      color: themed('text-contrast');
      &:visited {
        color: themed('text-contrast');
      }
      &:hover {
        color: themed('contrast-light');
        text-decoration: underline;
      }
    }
  }

  .custom-btn:disabled,
  .custom-btn:disabled:hover {
    color: $medium-gray;
    background-color: transparent;
  }
}

/** FeederDetails styles **/
.feeder-details {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  &-body {
    padding: 0px 20px;
  }
  &-footer {
    background-color: $panel-black;
  }
  &-navigation {
    margin-top: 2px;
    display: flex;
    justify-content: space-between;
  }
  &-btn {
    width: 49.75%;
    padding: 10px;
    background-color: $dark-charcoal;
    border: none;
    color: $medium-gray;
    &:disabled {
      color: $panel-black;
    }
  }
}
