@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";

@import '../styles/base';

.custom-btn {
  @extend .flex-centered;
  border: none;
  border-radius: 4px;
  color: $white;
  cursor: pointer;
  font-size: $body-text;
  height: 36px;
  overflow: hidden;
  padding: 0 12px;

  .btn-loading {
    margin-left: 10px;
  }

  &.primary-btn {
    &.dark {
      background-color: $medium-teal;
      &:hover {
        background-color: $light-teal;
      }  
    }

    &.light {
      background-color: $light-teal;
      &:hover {
        background-color: $medium-teal;
      }  
    }
  }

  &.secondary-btn {
    &.light {
      background-color: transparent;
      border: 1px solid #606060;
      color: #606060;
      &:hover {
        background-color: #F5F5F5;
      }  
    }

    &.dark {
      background-color: #707070;
      &:hover {
        background-color: #959595;
      }  
    }
  }

  &.text-btn {
    background-color: transparent;
    color: $light-teal;
    font-size: $body-text;
    padding: 0;
    &:disabled {
      cursor: default;
    }
    &.light:disabled {
      color: #cccccc;
    }
    &.dark:disabled {
      color: #606060;
    }
  }

  &.tour-btn {
    &.dark, &.light {
      background-color: white;
      color: #1675EB;
      &:hover {
        background-color: #f5f5f5;
      }  
    }
  }

  &.primary-btn, &.secondary-btn, &.tour-btn {
    &:disabled, &:disabled:hover, &:disabled:focus {
      border: none;
      cursor: default;
      &.light {
        background-color: #e3e3e3;
        color: #aaaaaa;
      }
  
      &.dark {
        background-color: #424242;
        color: #7b7b7b;
      }
    }  
  }

  &.circular-type {
    padding: 0.5em;
    border-radius: 50%;
  }  
  .arrow_down{
    margin-left: 10px;
  }
}