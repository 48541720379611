@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


@mixin activeTile($type, $height) {
  &::before {
    background: transparent;
    content: '';
    display: inline-block;
    height: $height;
    width: 8px;
  }

  &.active-tile {
    background-color: $medium-charcoal;
    color: $light-teal;

    &::before {
      content: '';
      display: inline-block;
      width: 8px;
      height: $height;
      @if $type == 'stripe' {
        @include applyTheme($themes) {
          background: repeating-linear-gradient(
            45deg,
            themed('contrast-light'),
            themed('contrast-light') 4px,
            themed('contrast-neutral') 5px,
            themed('contrast-neutral') 10px
          );
        }      
      } @else {
        @include applyTheme($themes) {
          background: themed('contrast-light');
        }      
      }
    }

    @include applyTheme($themes) {
      background-color: themed('background-neutral');
      color: themed('contrast-light');
    }
  }
}

/** NetworkLeftRail Styles **/
.network-left-rail {
  background-color: $dark-charcoal;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @include applyTheme($themes) {
    background-color: themed('background-dark');
  }

  .all-feeders {
    width: 100%;
    padding: 20px;
    @include applyTheme($themes) {
      background-color: themed('background-neutral');
    }

  }

  .permission-disabled-tip {
    text-align: center;
    .tooltip {
      width: 100%;
    }
    .tooltip-contents {
      z-index: 401; // Allow tooltip to display over leaflet map
      font-weight: normal;
    }
  }
  .left-rail-header {
    display: flex;
    height: 50px;
    flex-shrink: 0;
    .header-btn {
      background-color: transparent;
      border: none;
      border-bottom: 2px solid $dark-gray;
      cursor: pointer;
      flex-grow: 1;
      font-size: $title-text;
      font-weight: bold;
      padding-bottom: 2px;
      transition: 0.2s ease all;
      @include applyTheme($themes) {
        color: themed('text-dark');

        &--active {
          border-bottom-width: 4px;
          border-bottom-style: solid;
          border-color: themed('contrast-light');
          color: themed('contrast-light');
          padding-bottom: 0;
        }

        &:disabled {
          background-color: themed('background-very-dark');
          color: themed('text-disabled');
          cursor: not-allowed;
        }
      }
    }
  }

  .left-rail-tab-panel {
    &.react-tabs__tab-panel--selected {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    &.asset-panel {
      .search-container {
        border-bottom: none;
      }
      .asset-search-header {
        border-bottom: 2px solid #d4d4d4;
        @include applyTheme($themes) {
          border-color: themed('background-dark');
        }

        .search-filter-row {
          display: flex;
          align-items: center;
          padding: 5px 10px 10px 10px;
        }
      }
    }

    .search-container .search-input:focus {
      @include applyTheme($themes) {
        box-shadow: 0 0 7px themed('contrast-light');
      }
    }

    .import-data {
      @extend .flex-centered;
      border-top: 1px solid $panel-black;
      border-bottom: 1px solid $panel-black;
      color: $medium-gray;
      flex-direction: column;
      flex-shrink: 0;
      font-weight: bold;
      font-size: $title-text;
      height: 125px;
      text-decoration: none;
      width: 100%;
      @include applyTheme($themes) {
        border-color: themed('background-very-dark');
      }

      &-plus {
        @extend .flex-centered;
        background-color: $dark-gray;
        border-radius: 40px;
        border: none;
        color: #262626;
        font-size: 60px;
        height: 70px;
        margin: auto;
        width: 70px;
        @include applyTheme($themes) {
          color: themed('background-dark');
          background-color: themed('text-main');

          &--disabled {
            background-color: themed('text-disabled');
          }
        }
      }

      &-text {
        color: $medium-gray;
        margin-top: 15px;
        @include applyTheme($themes) {
          color: themed('text-main');
          &--disabled {
            color: themed('text-disabled');
          }
        }
      }
      &--disabled {
        cursor: not-allowed;
      }
    }

    .asset-navigation {
      height: 150px;
      width: 275px;
    }

    .asset-selection {
      height: 100%;
      min-height: 0;
      display: flex;
      width: 275px;

      &-scrollbar,
      .instance-dropdown-scrollbar {
        .ps__rail-y {
          width: 8px;
          z-index: 100;
        }
        .ps__rail-y.ps--clicking .ps__thumb-y,
        .ps__rail-y:focus > .ps__thumb-y,
        .ps__rail-y:hover > .ps__thumb-y {
          width: 6px;
        }
      }

      .instance-dropdown-scrollbar {
        .ps__rail-y {
          margin-right: 3px;
          &:hover .ps__thumb-y {
            background-color: rgba($light-teal, 0.75);
          }
        }

        .ps__thumb-y {
          background-color: rgba($light-teal, 0.5);
        }
      }
    }

    .asset-type-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: $panel-black;
      @include applyTheme($themes) {
        background-color: themed('background-very-dark');
      }

      &:last-of-type {
        border-bottom: 1px solid $panel-black;
        @include applyTheme($themes) {
          border-color: themed('background-very-dark');
        }
      }
      &-header {
        border: none;
        border-top: 1px solid $panel-black;
        border-bottom: 1px solid $panel-black; 
        display: flex;
        flex-direction: row;
        align-items: center;
        @include applyTheme($themes) {
          background-color: themed('background-dark');
          border-top-color: themed('background-very-dark');
          border-bottom-color: themed('background-very-dark');

          &:hover {
            background-color: themed('background-neutral');
          }  
        }

        @include activeTile('stripe', 35px);

        .custom-checkbox {
          margin-left: 8px;
        }
      }
    }

    .asset-type-list {
      margin: 0;
      overflow-y: hidden;
      padding: 0;
      transition: max-height 0.3s ease;
      &--closed {
        max-height: 0px;
      }
      &--opened {
        max-height: 200px;
      }
    }

    .default-tile-border {
      border: none;
      border-top: 1px solid $panel-black;
      border-bottom: 1px solid $panel-black;
      @include applyTheme($themes) {
        border-color: themed('background-very-dark');
      }
    }

    .default-tile-style {
      background-color: transparent;
      border: none;
      border-left: 8px solid transparent;
      color: inherit;
      outline: none;
    }

    .default-tile-hover {
      background-color: $medium-charcoal;
      border: none;
      border-left: 8px solid $medium-charcoal;
      outline: none;
      @include applyTheme($themes) {
        background-color: themed('background-neutral');
        border-color: themed('background-neutral');
      }
    }

    .asset-type-tile {
      border: none;
      background-color: transparent;
      color: $medium-gray;
      cursor: pointer;
      margin: 0;
      padding: 0;
      width: 100%;
      outline: none;
      @include applyTheme($themes) {
        color: themed('text-main');
      }
      &--open {
        background-color: $medium-charcoal;
      }
      &:active div {
        position: relative;
        top: 0;
        left: 0;
      }

      &:disabled {
        cursor: default;
        @include applyTheme($themes) {
          color: themed('text-disabled');
        }
      }
      &-contents {
        @extend .default-tile-style;
        color: inherit;
        display: flex;
        justify-content: space-between;
        padding: 5px 12px 5px 0;
        text-align: left;
        &__p {
          align-items: center;
          display: flex;
          font-size: $body-text;
          justify-content: space-between;
          width: 100%;
        }
      }
    }

    .asset-type-instance {
      @extend .default-tile-border;
      background-color: transparent;
      font-size: $body-text;
      list-style: none;
      margin: 0;
      padding: 0;
      cursor: pointer;
      @include applyTheme($themes) {
        background-color: themed('background-dark');
        color: themed('text-main');
      }

      &__btn {
        align-items: center;
        background-color: transparent;
        border: none;
        color: inherit;
        cursor: pointer;
        display: flex;
        height: 100%;
        overflow: hidden;
        padding: 0;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        &:hover {
          @include applyTheme($themes) {
            background-color: themed('background-neutral');
          }
        }
  
        @include activeTile('solid', 30px);

        span {
          padding-left: 40px;
        }
      }
    }

    .feeder-selection {
      .expandable-section .content{
        padding: 5px 0;
      }
      height: 100%;
      display: flex;
      flex-direction: column;

      .feeder-list-loading {
        @extend .flex-centered;
        color: $light-gray;
        flex-direction: column;
        height: 100%;
        width: 100%;
        .material-icons {
          margin-bottom: 20px;
        }
      }
      .container-tile-icon{
        width: 20px;
        padding-top: 3px;
        .asset-main {
          @include applyTheme($themes) {
            color: themed('text-contrast');
          }
        }
      }
      .feeder-tile {
        border: none;
        padding: 12px;
        color: $light-gray;
        cursor: pointer;
        display: flex;
        outline: none;
        padding: 0;
        width: 95%;

        @include applyTheme($themes) {
          background-color: themed('background-dark');
          color: themed('text-main');
        }

        &-contents {
          display: flex;
          align-items: center;
          height: 24px;
          min-width: 180px;
          margin-right: 5px;
         
          .feeder-name {
            align-self: left;
            display: inline-block;
            line-height: 24px;
            margin-left: 5px;
            overflow: hidden;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 120px;
          }
        }
        &:hover {
          @include applyTheme($themes) {
            color: themed('text-contrast');
          }
        }

        &.new-feeder-button{
          border-top: none;
          border-bottom: 1px solid $panel-black;
          padding: 12px 20px;
          @include applyTheme($themes) {
            border-bottom-color: themed('background-very-dark');
          }
        }

        &.feeder-tile--active,
        &.feeder-tile--active:hover {
          @include applyTheme($themes) {
            background-color: themed('background-light');
            color: themed('text-contrast');
          }
        }
      }
    }
    .container-section {
      @include applyTheme($themes) {
        &.bordered {
          border-bottom: 1px solid themed('background-very-dark');
        }
      }

      .header {
        padding: 12px;
        @include applyTheme($themes) {
          color: themed('text-contrast');
        }
        .header-content {
          width: 90%;
          .tip-span { 
            display: flex; 
            .icon-section{
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .icon-section > * + * {
              margin-left: 5px;
            }
          }
          .feeder-tile,
          .feeder-tile:hover,
          .container-name-tile,
          .container-name-tile:hover {
            background: transparent;
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }
      }
      &--active, .container-name-tile,
      .container-name-tile:hover {
        @include applyTheme($themes) {
          background-color: themed('background-light');
          color: themed('text-contrast');
        }
      }
    }
    .react-tabs__tab-panel--selected {
      padding: 0;
    }
  }
}
