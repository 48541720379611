@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.settings-view-container {
  flex-direction: column;
  background:
    radial-gradient($dark-charcoal 1px, transparent 13%) 0 0,
    radial-gradient($dark-charcoal 1px, transparent 13%) 22px 22px;
  background-color:$panel-black;
  background-size:22px 22px;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  width: 100%;

  .settings-tab {
    display: flex;
    flex-direction: column;
    width: 100%;
    .cost-section{
      .number-input__input, .number-input-container{
        width: 100%;
        border-radius: 3px;
      }
      .number-input-group {
        width: calc(100% + 10px);
        margin: 0;
        min-height: 52px;
        .number-input { margin: 0 }
        .input-error { margin-top: 5px; }
        .input-error p { padding-right: 8px; }
      }
    }
    .number-input-unit{
      background: transparent;
      justify-content: flex-start;
      padding-right: 0;
      width: 0;
    }
  }
  .input-section, .setting-container{
    display: flex;
    flex-direction: column;
  }
  .input-section {
    margin-bottom: 10px;
  }
  .setting-container {
    width: 500px;
    margin: 20px;
  }
  .setting-label {
    margin: 10px 0;
    font-size: 16px;
    line-height: 16px;
  }
  .setting-text {
    margin: 0px;
    font-size: $caption-text;
    line-height: 30px;
  }

  .save-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    align-content: center;
  }
  .error-text {
    color: $warning-red;
    display: flex;
    margin-top: 20px;
    margin-left: 20px;
    i {
      margin-right: 10px;
    }
  }

  @include applyTheme($themes) {
    background:
    radial-gradient(themed('background-dark') 1px, transparent 13%) 0 0,
    radial-gradient(themed('background-dark') 1px, transparent 13%) 22px 22px;
    background-color: themed('background-very-dark');
    background-size: 22px 22px;
    color: themed('text-main');
  }

  .settings-card-container {
    @extend .flex-centered;
    width: 100%;
    height: calc(100% - 50px);
  }

  .card {
    width: calc(100% - 100px);
    height: calc(100% - 100px);
  }
}
