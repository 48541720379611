@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.analysis-results {
  &-section {
    .parameter-select {
      margin: 10px 0;
    }
  }
}

.analysis-results-controls {
  display: flex;
  margin: 10px 0;
}

.analysis-results-controls-button:first-of-type{
  margin-right: 24px !important;
}
