@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.powerflow-slider-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  position: relative;

  // Time Range Slider Styles
  .powerflow-slider {
    @extend .flex-centered;
    color: inherit;
    flex-grow: 1;
    height: 100%;
    padding: 30px 30px 0 0;

    .simple-range-slider-track {
      @include applyTheme($themes) {
        background-color: tryTheme('contrast-light', rgba, 0.3) !important;
      }
    }

    .simple-range-slider-rail {
      @include applyTheme($themes) {
        background-color: themed('background-very-dark');
      }
    }

    .simple-range-slider-tick-value {
      @include applyTheme($themes) {
        color: themed('text-contrast');
      }
    }

    .simple-range-slider-tick-line {
      @include applyTheme($themes) {
        background-color: themed('background-neutral');
      }
    }

    .jump-btn {
      @include applyTheme($themes) {
        color: themed('text-contrast');
        border-color: themed('text-contrast');
        &:disabled {
          color: themed('contrast-light');
          border-color: themed('contrast-light');
        }
      }
    }

    .zoom-button {
      @include applyTheme($themes) {
        color: themed('text-main');
      }
    }
  }

  .overlay-button {
    background-color: transparent;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    text-decoration: underline;
    padding: 2px 5px;
    @include applyTheme($themes) {
      color: themed('contrast-light');
    }

    i {
      margin-left: 10px;
    }
  }

  .time-range-msg {
    align-items: center;
    display: flex;
  }

  .no-results {
    @extend .flex-centered;
    background-color: rgba(0,0,0, 0.5);
    position: absolute;
    top: 45px;
    left: 10px;
    height: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: $caption-text;
    color: $light-gray;
  }

  .powerflow-error {
    background-color: rgba(0,0,0, 0.8);
    position: absolute;
    top: 40px;
    left: 0px;
    height: 45px;
    width: calc(100% - 30px);
    display: flex;
    justify-content: center;
    align-items: center;
    @extend .flex-centered;
    font-size: $body-text;
    color: $light-gray;
    z-index: 1000;

    &__i {
      color: red;
      margin-right: 10px;
    }
  }

  .date-range-widget {
    flex-shrink: 0;
    height: 50px;
    padding: 0 20px;
    width: 270px;
    z-index: 5; // must be > powerflow-slider for tooltips
    &--disabled {
      cursor: not-allowed;
    }
  }
}


