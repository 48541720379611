@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.validation-dashboard {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;

  .dashboard-card {
    width: 400px;
    height: 300px;

    .content {
      display: flex;
      flex-direction: column;
      height: 240px;
    }

    &-contents {
      flex-grow: 1;
    }
  }

  .dashboard-tooltip {
    padding: 10px;

    @include applyTheme($themes) {
      background: themed('background-dark');
      border: 1px solid themed('text-contrast');
      color: themed('text-contrast');
    }
  }
}