@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.inverter-unit-field {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 37px;

  &-button{
    & > span.icon-container {
      font-size: 17px !important;
      height: 17px;
      width: 17px;
    }
  }

  .number-input-group .number-input__label {
    margin-right: 12px;
  }
  .panel.single-row-input.number-input-group {
    .number-input__input {
      width: 58px;
    }
    .number-input-unit {
      align-self: center;
      padding: 0;
    } 
  }
}
.info-copy{
  margin-right: 5px;
}
