$success: #2FA70C;
$error: #CF3807;
$warning: #F3A200;
$info: #4F6990;
$error-lighter-red: #D53831;

$white : #FFF;
$white-20: rgba(255, 255, 255, 0.2);
$white-50: rgba(255, 255, 255, 0.5);
$white-70: rgba(255, 255, 255, 0.7);

$off-white: #F7F7F7;

$very-light-teal: #50c7c2;
$light-teal: #06AFA8;
$medium-teal: #0A7E79;
$dark-teal: #005753;

$teal-stripes: linear-gradient(45deg, $very-light-teal 0%, $very-light-teal 30%,
  $light-teal 30%, $light-teal 70%, $very-light-teal 70%);
$teal-thin-stripes: repeating-linear-gradient(135deg, $medium-teal 0%,
  $medium-teal 2%, $light-teal 2%, $light-teal 4%);

$light-blue: #A3C7EB;
$pacific-blue: #009DDC;
$navy-blue: #0079C1;
$dark-blue: #5A9CDE;
$blue-gray: #8894AF;
$solid-blue: #0337A4;
$gray-blue: #F1F5F7;
$lighter-blue: #ECF3FA;
$light-green: #c1f5c2;
$dark-green: #008000;

$light-red: #ffcccc;
$salmon: #FC7169;

$light-gray-border: #E0E0E0;
$lighter-gray: #a0a0a0;
$lighter-gray-opacity-70: rgba(160,160,160,0.7);
$very-light-gray: #F1F2F2;
$light-gray: #D4D4D4;
$very-lighter-gray: #cccccc;
$medium-gray: #949899;
$medium-gray-90: #909090;
$dark-gray: #606060;
$dark-gray-30: #303030;
$darker-gray: #404040;
$transparent-gray: rgba(209,209,209,0.3);

$medium-charcoal: #383838;
$dark-charcoal: #262626;

$panel-gray: #1b1b1b;
$panel-black: #151515;

$black: #000;
$black-10: rgba(0,0,0,0.1);
$black-20: rgba(0,0,0,0.2);
$black-60: rgba(0,0,0,0.6);
$black-70: rgba(0,0,0,0.7);

$warning-red: #FF3A00;

$load-highlight: rgba(255,110,110,1);
$generation-highlight: rgba(122,212,0,1);


$primary-highlights: #0337A4;
$primary-accent: #E71865;
$primary-white: #FFFFFF;
$primary-black: #000000;
$secondary-background: #F9FBFD;
$secondary-overlay: #ECF3FA;
$secondary-caption: #95A6B8;
$secondary-optional: #4F6990;
$graph-g1: #00396F;
$graph-g2: #0337A4;
$graph-g3: #2265DE;
$graph-g4: #2785FC;
$graph-g5: #7792CE;
$graph-g6: #98C3FF;
$graph-g7: #CDE0FF;
$graph-g8: #00B396;
$graph-g9: #77DAC6;
$graph-g10: #A3DECB;
$graph-g11: #D8EDC5;
$indicator-positive: #2FA70C;
$indicator-negative: #CF3807;
$indicator-neutral: #F3A200;
$indicator-neutral2: #0337A4;
