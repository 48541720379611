@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/_theme.scss';
@import '~github-markdown-css/github-markdown.css';

.notes-panel {
  width:100%;
  height: 100%;

  .create-button {
    margin-top: 5px;
    margin-left: 10px;
  }

  .load-message,
  .error-message {
    padding-left: 10px;
  }

  .note-card {
    width: calc(100% - 10px);
    height: 260px;

    .button-container {
      display: flex;
      flex-direction: row;
    }

    .content {
      height: 200px;
      display: flex;
      flex-direction: column;
    }

    .editor {
      flex-grow: 1;
    }

    .viewer {
      flex-grow: 1;
      width: 100%;
      overflow-y: auto;
    }

    .description-editor {
      resize: none;
      height: 100%;
      width: 100%;
      padding: 5px;
  
      @include applyTheme($themes) {
        background-color: themed('background-dark');
        border: 1px solid themed('background-light');
        color: themed('text-main');
      }
    }
  
    .markdown-body {
      @include applyTheme($themes) {
        color: themed('text-main');
      }
  
      h1, h2 {
        @include applyTheme($themes) {
          border-bottom: 1px solid themed('background-light');
        }
      }
  
      pre {
        @include applyTheme($themes) {
          background-color: themed('background-dark');
        }
      }
  
      table {
        td {
          @include applyTheme($themes) {
            border: 1px solid themed('background-light');
          }
        }
  
        th {
          @include applyTheme($themes) {
            border: 1px solid themed('background-light');
          }
        }
  
        tr {
          @include applyTheme($themes) {
            background-color: themed('background-dark');
            border-top: themed('background-light');
          }
        }
  
        tr:nth-child(2) {
          @include applyTheme($themes) {
            background-color: themed('background-neutral');
          }
        }
      }
    }
  }
}