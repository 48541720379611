@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.delete-modal {
    .modal-body {
        &--active {
            position: relative;
        }
        .tip-span {
            position: absolute;
            top: 15px;
            right: 15px;
        }
        .modal-message__p {
            width: 350px;
            max-width: 100%;
        }
    }
    .modal-footer { 
        justify-content: flex-start; 
        .custom-btn { font-size: $article-text; font-weight: 600; }
        .primary-btn, .primary-btn:hover {
            background-color: $solid-blue;
        }
        .secondary-btn {
            border-color: $lighter-blue;
            background-color: $gray-blue;
            color: $solid-blue;
        }
    }
}
.delete-modal.light {
    .modal-header, .modal-message {
        color: $black;
    }
}
