@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.calendar-picker-container {
  position: relative;
  .calendar-button {
    width: 428px;
    font-size: $body-text;
    @include applyTheme($themes) {
      color: themed('contrast-light');
    }
    &:hover {
      @include applyTheme($themes) {
        background-color: themed('background-neutral');
      }
    }
    .calendar-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .calendar-container {
    position: absolute;
    bottom: 80%;
    right: -11%;
    width: 528px;
    height: 430px;
    z-index: 1;
    .type-calendar {
      display: flex;
      flex-direction: column;
      .close-button-row {
        display: flex;
        justify-content: flex-end;
      }
      .menu-calendar {
        display: flex;
        .menu-column {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 80px;
          .text-btn:focus {
            outline-width: 0;
          }
          .text-btn:disabled {
            @include applyTheme($themes) {
              color: themed('text-disabled');
            }
          }
        }
        .calendar-apply {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          width: 396px;
          padding-right: 26px;
        }
      }
      
      .calendar-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        .current-selection-container {
          height: 40px;
          .current-selection {
            display: flex;
            width: 100%;
            font-size: $caption-text;
            &:first-of-type {
              padding-bottom: 5px;
            }
          }
        }
      }
    }
  }
  &.style-for-timebar {
    .calendar-container {
      bottom: 80%;
    }
  }
  &.style-for-topnav {
    border-radius: 4px;

    .text-btn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-right: 5px;
      &:hover {
        background-color: transparent;
      }
      &:focus {
        outline-width: 0; 
       }
    }

    .calendar-container {
      top: 80%;
      left: -11px;
      width: 425px;
      .type-calendar {
        .calendar-apply {
          width: 314px;
        }
        
      }
    }

    // these should match the values in Select.js
    // applyTheme doesnt work for these (why?) so we are doing it manually
    &.light {
      border: 1px solid #d4d4d4;
      .text-btn {
        color: #262626;
        &.selected {
          color:#06AFA8;
        }
      }
      
      background-color: #FFFFFF;
    }
    &.dark {
      border: 1px solid #606060;
      .text-btn {
        color: white;
        &.selected {
          color:#06AFA8;
        }
      }
      background-color: #303030;
    }
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
    &:hover {
      border: 1px solid #06afa8;
    }
  }
}

.not-selected {
  @include applyTheme($themes) {
    color: themed('text-main');
  }
  :disabled {
    @include applyTheme($themes) {
      color: themed('text-contrast');
    }
  }
}
