@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


// Date Selector Styles
.date-range-label {
    padding: 5px 0;
}

.date-range-controls {
    align-items: center;
    display: flex;

    .date-picker.light .date-picker__input {
        border-color: #d4d4d4;
    }
    .date-picker.dark .date-picker__input {
        border-color: #606060;
    }

    .date-picker__input {
        background-color: transparent;
        margin-right: 10px;
        text-align: center;
        width: 95px;
    }

    .custom-select__menu {
        width: 110px;
    }
}
