@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.cost-table {
  font-size: 12px;
  border-spacing: 10px 0px;
  white-space: nowrap;
  margin-bottom: 10px;
  @include applyTheme($themes) {
    color: themed('text-contrast');
  }
  
  tr {
    height: 20px;

    &.divider {
      height: 1px;

      td {
        border-top: 1px solid;
        @include applyTheme($themes) {
          border-color: themed('text-main');
        }
      }
    }
  }

  th:not(:empty) {
    font-weight: normal;
    padding: 3px 5px;
    @include applyTheme($themes) {
      background: themed('background-neutral');
    }
  }

  .data-cell {
    text-align:center;
  }

  .header-cell {
    text-align: right;
    @include applyTheme($themes) {
      color: themed('text-main');
    }

    &.bold {
      font-weight: bold;
    }
  }
}
.cost-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 335px;

  .custom-btn {
    font-size: $caption-text;
  }
}
