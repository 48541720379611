@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.three-panel-layout {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &-header {
    background-color: $dark-charcoal;
    border-bottom: 1px solid $panel-black;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    width: 100%;
    @include applyTheme($themes) {
      background-color: themed('background-dark');
      border-color: themed('background-very-dark');
      color: themed('text-main');
    }
  }

  &-contents {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    min-height: 0;

    .main-container {
      width: 100%;
      display: flex;
      position: relative;
    }
  }

  &-left {
    display: flex;
    width: 275px;
    flex-shrink: 0;
    height: 100%;
    background-color: $dark-charcoal;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.2);
    z-index: 1; // One higher than the map
  }

  &-right {
    box-shadow: -2px 1px 3px 0 rgba(0,0,0,.2);
    display: flex;
    flex-direction: column;
    width: 375px;
    height: 100%;
    min-height: 0;
    position: absolute;
    right: 0;
    z-index: 1;

    &--inactive {
      padding: 0;
      width: 0px;
    }
  
    &--active {
      width: 375px;
      height: 100%;
      min-height: 0;
    }
  
    &--expanded {
      width: 100%;
    }
  }

  &-footer {
    border-top: 1px solid $panel-gray;
    height: 90px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    position: relative;
    @include applyTheme($themes) {
      background-color: themed('background-dark');
      border-color: themed('background-very-dark');
      color: themed('text-contrast');
    }
  }
}
