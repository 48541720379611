@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


/** AssetPanel Component Styles **/
.sliding-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;
  position: absolute;
  right: 0;
  z-index: 1000;

  .panel-icons-row {
    border-bottom: 1px solid;
    padding: 0 15px;
    @include applyTheme($themes) {
      background-color: themed('background-dark');
      border-bottom-color: themed('background-very-dark');
    }
    &--expanded {
      padding: 0 60px;
    }
  
    .asset-panel-icons {
      height: 50px;
      width: 345px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .expanded-container {
      display: flex;
      justify-content: center;
      width: 50px;
    }
  }


  &-container {
    display: flex;
    color: $white;
    background-color: $dark-charcoal;
    height: 100%;
    min-height: 0;
    transition: width 0.4s ease;
    overflow: hidden;
    width: inherit;
    @include applyTheme($themes) {
      background-color: themed('background-dark');
      color: themed('text-contrast');
    }
    &--expanded {
      padding: 15px 60px 0 60px;
    }
  }

  .expand-panel {
    padding: 15px 15px 0 15px;
    @include applyTheme($themes) {
      background-color: themed('background-dark');
      color: themed('text-contrast');
    }
    &--expanded {
      padding: 15px 60px 0 60px;
    }
  }

  &-contents {
    display: flex;
    flex-direction: column;
    width: inherit;
  }

  .chart-container {
    height: 200px;
  }


  /** AddBatteryDevice and AddPVDevice Styles **/
  .add-device-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .add-device__btn {
      height: 25px;
      .add-device-icon {
        margin-right: 10px;
      }
    }

    .add-device-form {
      display: flex;
      flex-direction: column;
      transition: min-height 0.5s ease, max-height 0.7s ease;
      &--open {
        min-height: 100px;
        max-height: 400px;
        height: auto;
        .create-asset-btn {
          margin-bottom: 20px;
        }
      }
      &--closed {
        min-height: 0px;
        max-height: 1px;
        height: 1px;
      }
    }

    .add-device-feedback {
      margin-top: 5px;
    }
  }

  /* ChargeDischargeBatterySchedule styles */
  .schedule-container {
    margin: 15px 0;
    @include applyTheme($themes) {
      color: themed('text-main');
    }

    .header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    h4 {
      font-size: $title-text;
    }
    .schedule-panel {
      display: flex;
      justify-content: flex-end;
      background-color: transparent;
      border: none;
      align-items: center;
      padding: 0;
      width: inherit;
      color: inherit;
    }

    .edit--disabled {
      color: $dark-gray;
      cursor: not-allowed;
    }

    .schedule-chart-container {
      border-radius: 4px;
      padding: 5px 5px 5px 5px;
      margin: 0;
      height: 280px;
      &-previous {
        height: 330px;
        .recharts-responsive-container {
          height: calc(100% - 50px) !important;
        }
      }
    }

    .schedule-chart-container > div {
      height: calc(100% - 24px);
      width: 100%;
    }

    .schedule-buttons {
      display: flex;
    }

    .error {
      color: red;
      padding: 12px 8px 24px 12px;
    }

    .error-message {
      padding-left: 12px;
    }

    .schedule-legend-text {
      margin: 0 0 20px 0;
      text-align: end;
      font-weight: 400;
      font-size: $caption-text;
    }
    h4 {
      font-weight: normal;
      margin: 0;
    }
    .graph-placeholder {
      @extend .flex-centered;
      width: 100%;
      min-height: 100%;
      height: 100%;
    }
  }

  .button-link {
    background-color: transparent;
    border: none;
    color: $light-teal;
    cursor: pointer;
    text-decoration: underline;
    padding: 0;

    &:hover {
      color: $very-light-teal;
    }
  }

  .enrollment-btn {
    height: 50px;
    width: 50px;
  }

  .enrollment-form__btn-group .custom-btn:first-of-type {
    margin-right: 12px;
  }

  .no-asset-selected {
    height: 100%;
    width: 100%;
    min-width: 375px;
    display: flex;
    flex-direction: column;

    h2 {
      color: $light-teal;
      font-size: $headline-text;
      padding-left: 20px;
    }

    .panel-contents {
      @extend .flex-centered;
      color: $medium-gray;
      height: 100%;
      font-size: $title-text;
      text-align: center;
      .no-asset-icon {
        height: 100px;
        width: 200px;
      }
    }
  }

  .expandable-section {
    .header {
      @include applyTheme($themes) {
        color: themed('text-contrast');
      }  
      font-size: $title-text;
      margin: 5px 0;
      &:hover {
        @include applyTheme($themes) {
          color: themed('contrast-light');
        }  
      }
      &:disabled {
        font-size: $title-text;
        @include applyTheme($themes) {
          color: themed('background-light');
        }  
      }
    }
  }

  .non-expandable-section .title>p {
    @include applyTheme($themes) {
      color: themed('text-contrast');
    }  
    font-size: $title-text;
  }
}
