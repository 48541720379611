@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.unit-label-row {
  color: $medium-gray;
}

.top-legend {
  display: flex;
  flex-direction: row;
  align-items: center;

  .legend-group {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    justify-content: flex-end;

    .schedule-panel-legend {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    .schedule-panel-legend-labels {
      display: flex;
    }
  }

  &--expanded {
      width: 100%;
  }
}

.schedule-container {
  .schedule-header {
    align-items: center;
    display: flex;
    font-size: $body-text;
    justify-content: space-between;
  }

  .tooltip {
    padding: 10px;
    border: 1px solid black;
    background-color: rgba(0,0,0,0.5);
    font-size: $body-text;

    @include applyTheme($themes) {
      background-color: tryTheme('background-very-dark', rgba, 0.75);
      border-color: themed('background-very-dark');
      color: themed('text-main');
    }

    th {
      border-bottom: 1px solid rgba(255,255,255,0.5);
    }
  }

  .missing-schedule-placeholder {
    @extend .flex-column;
    text-align: center;
    align-items: center;
    white-space: pre-wrap;
  }
}
