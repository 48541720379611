@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.inverter-constraint-diagram {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 16px;
    width: 100%;
  }
  
  .diagram {
    width: 250px;
    height: 250px;

    .axis {
      stroke-width: 2px;
      // stroke: rgb(61, 67, 83);
      @include applyTheme($themes) {
        stroke: themed('text-contrast');
      }

      &-arrow {
        // fill: rgb(61, 67, 83);
        @include applyTheme($themes) {
          fill: themed('text-contrast');
        }
      }
    }

    .axis-label {
      font-size: 12px;
      text-anchor: start;

      @include applyTheme($themes) {
        fill: themed('text-contrast');
      }
    }

    .p-q-rating {
      stroke-width: 2px;
      stroke-dasharray: 2 2;
      stroke: rgb(86, 92, 104);
    }

    .s-rating {
      stroke-width: 2px;
      stroke: rgb(36, 132, 219);
      fill: none;
    }

    .pf-rating {
      stroke-width: 2px;
      stroke: rgb(227, 30, 75);
    }

    .operating-region {
      stroke: none;
      fill: rgb(93, 140, 61);
      opacity: 0.3;
    }
  }

  .legend {
    padding-bottom: 10px;
    width: 100%;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 12px;
    }

    .legend-icon {
      width: 20px;
      display: inline-block;
      margin-bottom: 3px;
      margin-right: 10px;

      &-pq {
        border: 1px dashed rgb(86, 92, 104);
      }

      &-s {
        border: 1px solid rgb(36, 132, 219);
      }

      &-pf {
        border: 1px solid rgb(227, 30, 75);
      }
    }
  }
}