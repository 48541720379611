@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  // NavBar Styles
  .top-nav {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    &__img {
      height: 30px;
    }
    &__btn {
      align-items: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      height: 30px;
      outline: none;
    }

    &--right {
      align-items: center;
      display: flex;
      height: 100%;
      .share-button {
        padding: 0 15px;
      }
    }

    .username {
      align-items: center;
      border: none;
      border-radius: 30px;
      cursor: pointer;
      display: flex;
      height: 24px;
      margin: 0 10px 0 10px;
      overflow: hidden;
      padding: 0 10px 0 0;

      &-name {
        font-size: $body-text;
        margin-left: 5px;
      }

      .user-icon {
        font-size: $caption-text;
        top: -1px;
        &-person {
          font-size: $title-text;
          bottom: -2px;
        }
      }
      &:disabled {
        cursor: default;
      }
    }

    &.dark,
    &.light {
      background-color: $medium-charcoal;

      .username {
        background-color: $panel-black;
        color: $medium-gray;
      }
    }

    &.light.dsp {
      background: linear-gradient(180deg, #0079C1 0%, #016193 100%);

      .username {
        background-color: $transparent-gray;
        color: $white;
      }

      .top-nav__btn span {
        margin-bottom: 1px;
        margin-left: 7px;
      }
    }
  }

  // Route Contents Style
  .core-layout__viewport {
    display: flex;
    flex: 1;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    &--dark {
      background-color: $dark-charcoal;
    }
    

    .workspace-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .prototype {
    display: block;
  }
}

.top, .left {
  position: relative;
  border: 4px solid #1675EB;
}

.top:after, .top:before {
	bottom: 100%;
	left: 10%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.top:after {
	border-color: rgba(22, 117, 235, 0);
	border-bottom-color: #1675EB;
	border-width: 10px;
	margin-left: -10px;
}
.top:before {
	border-color: rgba(22, 117, 235, 0);
	border-bottom-color: #1675EB;
	border-width: 18px;
	margin-left: -18px;
}

.left:after, .left:before {
	right: 100%;
	top: 10%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.left:after {
	border-color: rgba(22, 117, 235, 0);
	border-right-color: #1675EB;
	border-width: 10px;
	margin-top: -10px;
}
.left:before {
	border-color: rgba(22, 117, 235, 0);
	border-right-color: #1675EB;
	border-width: 18px;
	margin-top: -18px;
}

.tour-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  .skip-btn {
    color: $light-gray;
    &:hover {
      color: white;
    }
  }
  .step-num {
    color: $light-gray;
    padding-right: 10px;
    font-size: $body-text;
  }
}

.jrBYqH {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert-message {
  border-radius: 5px;
  box-shadow: 0 12px 17px 0 rgba(0,0,0,0.3);
  display: flex;
  font-family: 'Roboto', sans-serif;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 48px;
  right: calc(50% - 200px);
  padding: 21px 24px;
  width: 380px;
  z-index: 1001;
  @include applyTheme($themes) {
    background-color: themed('background-neutral');
    color: themed('text-main');
  }

  .alert-message-header {
    display: flex;
    justify-content: space-between;
  }

  .alert-message-title {
    margin: 0;
    font-size: $title-text;
    font-weight: 400;
  }

  .alert-message-contents {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin: 15px 0;
  }

  .alert-message-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    width: 100%;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-weight: 500;
      text-align: right;
      text-transform: uppercase;
      min-width: 55px;
      @include applyTheme($themes) {
        color: themed('contrast-light');
      }
    }
  }

  .clear-alert-btn {
    background-color: transparent;
    border: none;
    margin-left: 15px;
    @include applyTheme($themes) {
      color: themed('text-main');
    }  
  }
}

@media (max-width: 660px) {
  .container .top-nav .username {
    display: none;
  }
};
