@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.validation-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 375px;
  padding: 10px 20px 20px 20px;
  @include applyTheme($themes) {
    color: themed('text-main');
  }
  &--expanded {
    padding: 10px 60px 20px 60px;
  }

  .validation-check-container {
    font-size: $body-text;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
  }

  .validation-check-message {
    margin: 10px 0;
  }

  .validation-totals-container {
    display: flex;
  }

  .validation-type-container {
    align-items: center;
    display: flex;
    margin: 0 10px 0 5px;
  }

  .validation-type-total {
    display: inline-block;
    margin-left: 10px;
  }

  .powerflow-ready-message {
    font-size: $body-text;
    display: inline-block;
    margin: 0 0 0 10px;
  }

  .validation-header {
    @include applyTheme($themes) {
      background-color: themed('contrast-neutral');
    }
  }

  .validate-loading {
    margin-left: 10px;
  }

  .alternating-colors {
    @include applyTheme($themes) {
      color: themed('text-main');
      li:nth-child(odd) {
        background-color: themed('background-neutral');
      }
      li:nth-child(even) {
        background-color: themed('background-very-dark');
      }  
    }
  }
}
