@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/_theme.scss';

.user-email {
    display: flex;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    .email {
        width: 200px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
      }
}

.user-permission {
    float: right;
    text-align: right;
}
