@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.dropdown {
  display: inline-block;
  position: relative;

  .dropdown-content {
    border-radius: 3px;
    overflow: hidden;
  }

  // Toggle Button Container Style
  .dropdown-toggle {
    background-color: inherit;
    border: none;
  }

  // Menu Items Container
  .dropdown-content {
    box-sizing: border-box;
    position: absolute;
    top: 43px;
    transition: all .5s ease;
    background-color: $white;
    min-width: 230px;
    overflow: hidden;
    z-index: 1;
    border-radius: 3px;
  }

  // StandardMenuItem Styles
  .dropdown-item__btn {
    position: relative;
    transition: all 0.5s ease;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    font-size: $body-text;
    border: none;
    width: 100%;
    overflow: hidden;
    &:hover {
      cursor: pointer;
    }
  }

  .dropdown-item {
    &__svg--check {
      margin-left: auto;
      pointer-events: none;
    }
    &__svg--main {
      margin-right: 10px;
      pointer-events: none;
    }
  }

  .selected {
    color: $dark-blue;
  }

  // Menu Horizontal Seperator Styles
  .seperator {
    width: 90%;
    height: 1px;
    border-top: 1px solid $light-gray-border;
    margin: 0 auto;
  }

  /** THEMES **/
  &.light {
    .dropdown-content {
      border: 1px solid $light-gray-border;
      background-color: $white;
      box-shadow: 0 1px 3px $lighter-gray-opacity-70, 0 1px 2px $lighter-gray-opacity-70;
    }
    .dropdown-item__btn {
      background-color: $white;
      color: $dark-gray;
      &:hover {
        background-color: $off-white;
      }
      &:disabled {
        color: $light-gray;
        cursor: not-allowed;
      }
    }
  }

  &.dark {
    .dropdown-content {
      border: 1px solid $dark-gray;
      background-color: $dark-gray-30;
      box-shadow: 0 4px 8px -2px $black, 0 19px 38px -2px $black;
    }
    .dropdown-item__btn {
      background-color: $dark-gray-30;
      color: $medium-gray;
      &:hover {
        background-color: $medium-charcoal;
        color: $light-teal;
      }
      &:disabled {
        color: $dark-gray;
        cursor: not-allowed;
      }
    }
  }
}
