@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.network-import-panel {
    width: 100%;

    select {
        background: transparent;
        width: 100%;
        border-radius: 3px;
        height: 30px;
    }

    &.dark {
        select {
            border-color: #606060;
            font-size: 16px;
            font-family: Roboto;
            color: #FFFFFF;
            &:disabled {
                background: #606060;
            }
        }

        option {
            background: #4F4F4F;
        }
        option:checked {
            background: #2E615F;
        }
    }

    &.light {
        select {
            outline: 0px;
            border-color: #D4D4D4;
            color: #262626;

            &:disabled {
                background: #CCCCCC;
            }
        }

        option:checked {
            background: #06AFA8;
        }
    }
}
