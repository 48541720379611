@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.error-type {
  align-items: center;
  display: flex;
  height: calc(100% - 76px);  // Account for header size
  justify-content: center;
  width: 100%;

  @include applyTheme($themes) {
    background: themed('background-dark');
    color: themed('text-contrast');
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    margin: 0;
    padding: 10px;

    .header {
      display: flex;
      width: 100%;
    }

    .title {
      margin: 0;
      padding: 0;
      color: $pacific-blue;
      text-decoration: underline;
    }
  }

  .message-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    .message {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 40px;
      
      @include applyTheme($themes) {
        border: 1px solid themed('background-very-dark');
      }
    }
  }
}
