@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.container-tooltip {
  @extend .flex-centered;
  flex-direction: column;
  padding: 5px;
  max-height: 190px;
  &-header {
    color: $medium-teal !important;
    font-size: $body-text;
    margin-bottom: 5px;
  }
  &-contents {
    width: 100%;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .multi-container-scroll {
      max-height: 140px;
      .ps__rail-x {
        display: none;
      }
      .ps__rail-y {
        width: 8px;
        z-index: 100;
        margin-right: 3px;
        right: 0px;
        &:hover .ps__thumb-y {
          background-color: rgba($light-teal, 0.75);
        }
      }
      .ps__rail-y.ps--clicking .ps__thumb-y,
      .ps__rail-y:focus > .ps__thumb-y,
      .ps__rail-y:hover > .ps__thumb-y {
        width: 6px;
      }

      .ps__thumb-y {
        background-color: rgba($light-teal, 0.5);
      }
    }
  }
}

.container-name-badge {
  align-items: center;
  background-color: $medium-teal;
  border-radius: 4px;
  color: $white;
  cursor: default;
  display: flex;
  font-size: $body-text;
  justify-content: space-between;
  margin-top: 3px;
  padding: 2px 5px;
  display: table;
}
