@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.grid-layout {
    display: flex;
    padding: 15px;
    flex-direction: column;
    .grid-layout-header {
        margin-bottom: 5px;;
    }
    .grid-layout-contents{
        width: 100%;
    }
    .grid-columns {
        display: grid;
        grid-gap: 15px;
        &.items-centered {
            align-items: center;
            min-height: 30px;
        }
    }
    .one-one {
        grid-template-columns: 1fr 1fr;
    }
    .one-two {
        grid-template-columns: 1fr 2fr;
    }
    .one-seven {
        grid-template-columns: 1fr 7fr;
    }
    .one-five-two {
        grid-template-columns: 1fr 5fr 2fr;
    }
    .two-two-four {
        grid-template-columns: 2fr 2fr 7fr;
    }
    
    .two-one-two-two {
        grid-template-columns: 2fr 1fr 2fr 2fr;
    }
    .two-two-two-one{
        grid-template-columns: repeat(3, minmax(0, 2fr)) 1fr;
    }
    .two-two-two-two {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .three-one-three {
        grid-template-columns: 3fr 1fr 3fr;
    }
    .three-three-one {
        grid-template-columns: repeat(2, minmax(0, 3fr)) 1fr;
    }    
    .eight {
        grid-template-columns: 8fr;
    }
}

