@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.zoomable-range-slider {
  width: 100%;

  .range-controls {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 2px 0;
    width: 100%;
    .date-controls {
      display: flex;
      align-items: center;
      .arrow-button-container {
        display: flex;
        justify-content: space-between;
        padding-right: 10px;
      }
    }
  }
  
  .zoom-placeholder {
    width: 80px;
    .jump-btn {
      background-color: transparent;
      border-radius: 5px;
      border: 1px solid $dark-gray;
      color: $white;
      cursor: pointer;
      font-size: $body-text;
      height: 28px;
      margin: 0 5px;
      width: 75px;
      outline: none;
      &:last-of-type {
        margin-right: 0;
      }
      &:hover {
        border: 1px solid $light-gray;
      }
      &:disabled {
        border-color: $light-teal;
        color: $light-teal;
        cursor: default;
      }
    }
  }

  .date-selector-menu {
    width: 75px;
    display: inline-block;
    margin: 0 5px;
    &:first-of-type {
      margin-left: 0;
    }

    // Hack to get menu to open up instead of down.
    .Select-menu-outer {
      position: absolute !important;
      top: auto !important;
      bottom: calc(100% - 1px) !important;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border-top-left-radius: 5px !important;
      border-top-right-radius: 5px !important;
    }
  
    &.is-open .Select-control {
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
      border-bottom-right-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
    }

    .Select-menu-outer {
      z-index: 10;
    }
  }
  .icon-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 14px;
    .icon-container {
      display: flex;
      flex-basis: 100%;
      justify-content: center;
      .icon {
        font-size: $body-text;
        color: red;
      }
    }
  }
  
}
