@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.settings {
  height: 100%;
  position: relative;
  width: 50px;

  .tip-span {
    height: 100%;
    width: 100%;
  }

  &-container {
    border-radius: 3px;
    box-shadow: 0 12px 17px 0 rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    max-height: 0px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    top: 50px;
    transition: 0.3s ease max-height, 0.2s ease padding;
    width: 175px;
    z-index: 2000;
    &--active {
      max-height: 450px;
    }
  }

  &-tile {
    @extend .flex-centered;
    flex-shrink: 0;
    font-size: $body-text;
    justify-content: center;
    height: 35px;
    padding-left: 24px;
    padding-right: 24px;
  }

  &-tile-theme {
    font-size: $body-text;
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    .radio-btn {
      margin-left: 0;
    }
  }

  &-tile-title {
    font-size: $body-text;
    margin: 0;
    padding: 0;
    padding-bottom: 5px;
  }

  &-btn-container {
    width: 100%;
    padding: 0px 24px 0px 24px;
    height: 48px;
    &:after {
      content:"";
      display: block;
      position: relative;
      top: 0;
      left: 2.5%;
      height: 1px;
      width: 95%;
    }
  }

  &-link {
    border: none;
    width: 100%;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: $white;
    }
  }

  &-logout__btn, &-tour__btn {
    border: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &:hover {
      color: $white;
    }
    display: flex;
    align-items: center;

    .logout-icon {
      padding-right: 15px;
      font-size: 20px;
    }
  }
  
  // Override to match notifications
  .icon-btn-container.light .icon-container {
    color: $white !important;
  }

  &__btn {
    @extend .flex-centered;
    height: 100%;
    &.icon-btn {
      height: 100%;
      width: 100%;
      &:hover {
        background-color: transparent !important;
      }
    }

    &--active {
      &.icon-btn .icon-container {
        color: #959595;
      }
    }
    &:hover, &--active, &:focus {
      outline: none;
      background-color: rgba(0,0,0,0.1);
    }
  }

  &-version-container {
    @extend .flex-centered;
    flex-direction: column;
    padding: 0 23px 7px;
    width: 100%;
    flex-shrink: 0;
  }

  &-logo {
    height: 25px;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 8px 0;
  }

  &-version {
    color: $light-gray;
    font-size: $caption-text;
    word-break: break-all;
    text-align: center;
    width: 100%;
  }

  .toggleswitch {
    margin-top: 10px;
  }

  &.dark {
    .settings {
      &-container {
        background-color: #404040;
        box-shadow: 0 12px 17px 0 rgba(0,0,0,0.5);
        color: $medium-gray;
      }

      &-tile {
        background-color: #404040;
        color: $white;
        &:hover,
        &:hover .settings-logout__btn,
        &:hover .settings-tour__btn {
          background-color: #4f4f4f;

        }
      }

      &-help-link {
        color: white;
        text-decoration: none;
      }

      &-separator {
        height: 1px;
        width: 100%;
        background: $dark-gray;
      }

      &-logout__btn, &-tour__btn {
        background-color: #404040;
        color: $white;
        padding: 0px;
        text-align: left;
        &:hover {
          background-color: #4f4f4f;
        }
      }

      &__btn {
        color: $medium-gray;
        &:hover, &--active, &:focus {
          color: $light-teal;
        }
      }
    }
  }

  &.light {
    .settings {
      &-container {
        background-color: #FAFAFA;
        box-shadow: 0 12px 17px 0 rgba(0,0,0,0.5);
        color: $dark-gray;
      }

      &-btn-container:after {
        background: #FAFAFA;
      }

      &-separator {
        height: 1px;
        width: 100%;
        background: $dark-gray;
      }

      &-tile {
        background-color: transparent;
        &:hover {
          background-color: $light-teal;
          color: $white;
          .settings-help-link,
          .settings-logout__btn,
          .settings-tour__btn {
            color: $white;
          }
        }

        .settings {
          &-logout__btn,
          &-tour__btn,
          &-help-link {
            background-color: transparent;
            color: $dark-gray;
            padding: 0px;
          }
        }
      }

      &__btn {
        color: $white;
      }

      &-version-container {
        &--EQL {
          background: $medium-charcoal;
        }
        padding-top: 3px;
      }

      &-version {
        color: $light-gray;
      }
    }
  }
}
