@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
#phase {
    .text-input-group .text-input {
        display: flex;
        justify-content: flex-start;
    }
    
    .panel.single-row-input.text-input-group .number-input__label, .panel.single-row-input.text-input-group .text-input__label {
        width: 120px;
    }
    .text-input-container {
        margin-left: 0;
    }
    .light.panel.single-row-input.text-input-group .text-input__input,
    .dark.panel.single-row-input.text-input-group .text-input__input {
        text-align: left;
    }
}
