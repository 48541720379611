@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.error-type-left-rail {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 200px;

  @include applyTheme($themes) {
    background: themed('background-dark');
    border-right: 1px solid themed('background-very-dark');
    color: themed('text-contrast');
  }

  .name-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;

    &-item {
      align-items: center;
      border: none;
      cursor: pointer;
      display: flex;
      height: 40px;

      .tip-span {
        overflow: hidden;
      }

      @include applyTheme($themes) {
        background-color: themed('background-dark');
        border-bottom: 1px solid themed('background-very-dark');
        color: themed('text-contrast');
      }

      &--selected {
        @include applyTheme($themes) {
          background-color: themed('background-light');
        }
      }

      .item-name {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}