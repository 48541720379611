@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.schedule-validations-icon {
  color: red;
  white-space: nowrap;
}

.schedule-validations-title {
  vertical-align: super;
}

.schedule-validations-container {
  top: 30px;
  position: absolute;
  display: block;
  pointer-events: none;

  &.light {
    .schedule-validations-list {
      background-color: $darker-gray;
      color: $medium-gray;
    }
  }

  &.dark {
    .schedule-validations-list {
      background-color: $off-white !important;
      color: $dark-charcoal;
    }
  }
};

.schedule-validations-list  {
  border: 1px solid black;
  border-radius: 5px;
  padding: 8px;
  float: left;
  position: relative;
  font-size: $caption-text;
};

.schedule-validations-list-item {
  padding: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
};
