@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '~normalize.css/normalize';
@import '~components/styles/_theme.scss';

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

button,
input,
optgroup,
select,
textarea {
  font-family: 'Roboto', sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

p {
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a,
a:link,
a:visited {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.underline {
  border-bottom: 1px dotted $medium-gray;
  padding-bottom: 3px;
}

.btn {
  padding: 5px 15px;
  border-radius: 4px;
}

.unstyled-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.unstyled-list {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

// For what-input library. Disables focus outline on mouse interaction
[data-whatintent='mouse'] *:focus {
  outline: none;
}

// Material Design Icons Iconfont
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url(~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff2)
      format('woff2'),
    url(~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff)
      format('woff'),
    url(~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.ttf)
      format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: $icon-size;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

// Default Confirmation Modal Settings
.modal .modal-overlay {
  z-index: 1001;
}

.modal-message__p {
  margin: 5px 0;
}

.modal-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: $dark-charcoal;

  .modal-header {
    background: #404040;
    color: white;
    height: 36px;
    text-align: center;
    font-size: $title-text;
    padding: 8px;
  }

  .modal-message {
    background: $dark-charcoal;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 10px;
    width: 100%;

    &__p {
      margin: 15px 0;
      color: #a0a0a0;
    }
  }

  .modal-footer {
    background: $dark-charcoal;
    color: #a0a0a0;
    text-align: center;

    button {
      background-color: $medium-teal;
      color: white;
      cursor: pointer;
      border-radius: 4px;
      border: none;
      min-width: 125px;
      padding: 5px 20px;
      &:hover {
        background-color: lighten($medium-teal, 5%);
      }
    }
  }
}

// UI-KIT Default Font Sizes

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}

h1 {
  font-size: $headline-text;
}
h2 {
  font-size: $title-text
}

.headline-text,
.title-text,
.body-text,
.caption-text {
  font-family: 'Roboto', sans-serif;
}

.headline-text {
  font-size: $headline-text;
}

.title-text {
  font-size: $title-text;
}

.body-text {
  font-size: $body-text;
}

.caption-text {
  font-size: $caption-text;
}

a.help-link {
  color: $light-teal;
  text-decoration: underline;
}

.section-divider {
  width: 100%;
  border: none;
  border-top: 1px solid $dark-gray;
  @include applyTheme($themes) {
    border-color: themed('background-neutral');
  }
}

.has-notification {
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 12px;
  height: 12px;
  top: 63px;
  right: 12px;
  border-radius: 50%;
  background-color: red;
  z-index: 100;
}

.custom-scrollbar {
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba($white, 0.2);
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba($white, 0.25);
  }
}

.gray-scrollbars {
  @extend .custom-scrollbar;

  &::-webkit-scrollbar-thumb {
    background: rgba($medium-gray, 0.5);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba($medium-gray, 0.75);
  }
}

.rotate {
  animation: rotate-clockwise 2s infinite linear;
}

.error {
  color: #D53831;
}

.success {
  color: $success;
}

.react-toast-notifications__container {
  .react-toast-notifications__toast {
    color: $white;
    font-size: $article-text;
    .react-toast-notifications__toast__countdown {
      background: center center no-repeat;
      opacity: 1;
      min-height: 32px;
    }
    &--success {
      background-color: $success;
      .react-toast-notifications__toast__countdown {
        background-image: url(/thumbs.svg);
      }
    }
    &--error {
      background-color: $error;
      .react-toast-notifications__toast__countdown {
        background-image: url(/icon-error.svg);
      }
    }
    &--warning {
      background-color: $warning;
      color: $black;
      .react-toast-notifications__toast__countdown {
        background-image: url(/warning.svg);
      }
    }
    &--info {
      background-color: $info;
      .react-toast-notifications__toast__countdown {
        background-image: url(/notification.svg);
      }
    }
    .react-toast-notifications__toast__content {
      min-height: 0;
      padding: 0;
      align-items: center;
      display: flex;
    }
    .react-toast-notifications__toast__icon-wrapper {
      background-color: initial;
      .react-toast-notifications__toast__icon { display:none !important }
    }
  }
}
// Animation to rotate an element on loop
@keyframes rotate-clockwise {
  from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }
}
.include-border {
  padding: 10px 15px;  
  @include applyTheme($themes) {
    border: 1px solid themed('background-light');
  } 
}