@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.bulk-lifecycle-header {
  @include applyTheme($themes) {
    color: themed('text-contrast');
  }
}

.bulk-lifecycle-section {
  @include applyTheme($themes) {
    color: themed('text-main');
    a {
      color: themed('contrast-light');
      text-decoration: underline;
    }
    .warning {
      margin: 10px 0;
      font-size: 13px;
      color: $warning-red;
    }
  }
}
