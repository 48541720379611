@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.usage-point {
  display: flex;
  flex-direction: column;
  width: 99%;
  padding-left: 5px;
  &-row {
    display: flex;
    justify-content: flex-end;
    min-height: 40px;
  }
  &-column {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $body-text;
    &--delete {
      @include applyTheme($themes) {
        color: themed('text-disabled');
      }
    }
    &--select {
      min-width: 125px;
    }
    &:first-of-type {
      justify-content: flex-start;
      text-align: left;
      width: 100%;
      padding-right: 4px;
    }
  }
  &-index {
    margin-right: 10px;
    font-size: 16px;
  }
  .icon-btn-container {
    padding-left: 4px;
    align-items: center;
    display: flex;
    .icon-btn:disabled {
      .material-icons {
        cursor: not-allowed;
      }
    }
  }
  &-add {
    padding: 10px 5px 0 5px;
  }
}

.asset-panel-values .usage-point .single-row-input{
  .input-error p { margin-right: 0 }
 .number-input {
    .number-input-container .number-input__input {
      width: 93px;
      &:disabled {
        @include applyTheme($themes) {
          color: themed('text-disabled');
        }
      }
    }
  }
}
