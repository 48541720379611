@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.accordion {
    min-height: 28px;
    border-radius: 5px;
    margin-bottom: 3px;
    font-size: $article-text;
    @include applyTheme($themes) {
      background-color: themed('background-dark');
      color: themed('text-main');
    }
    .accordion-header {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        padding: 2px 8px;
        border-radius: 5px;
        justify-content: space-between;
        @include applyTheme($themes) {
          color: themed('text-header');
          background: themed('contrast-border');
        }
        .title {
          font-size: $article-text;
          margin: 5px 0;
        }
        .button-menu{
          display: flex;
          position: absolute;
          top: 0;
          right: 0;
         .icon-btn-container .icon-btn, .icon-container { outline:none !important; }
       }
       &.active { border-radius: 5px 5px 0 0; }
    }
    .content {
      padding: 5px 8px;
      border-radius: 0px 0px 5px 5px;
      @include applyTheme($themes) {
        background: themed('contrast-border');
      }
    }
  &.include-border {
    padding: 2px 5px;  
    @include applyTheme($themes) {
      border: 1px solid themed('background-light');
    } 
  }
}
.accordion-section.dark {
  .accordion-header, .content { background-color: $dark-gray-30}
}