@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.number-input-all {
  margin: 3px 0;
  width: 100%;
  &.light {
    .number-input {
      &__input {
        background-color: transparent;
        border: 1px solid $light-gray;
        color: $dark-charcoal;
        &:disabled {
          background-color: $light-gray;
          color: $dark-gray;
        }
      }
      &-with-unit {
        border-radius: 4px 0 0 4px;
        outline: none;
      }
      &-unit {
        background-color: rgba(209,209,209,0.6);
        color: $dark-gray;
        border-radius: 0 3px 3px 0;
      }
    }
  }
  &.dark {
    .number-input {
      &__input {
        background-color: transparent;
        border-color: $dark-gray;
        color: $white;
        &:disabled {
          background-color: $medium-charcoal;
        }
      }
      &__label {
        color: $white;
      }
      &-unit {
        background-color: $dark-gray;
        color: $light-gray;
        border-radius: 0 3px 3px 0;
      }
      &-with-unit {
        border-radius: 4px 0 0 4px;
        outline: none;
      }
    }
  }

  &.modern {
    .number-input {
      .input-with-label {
        flex-direction: column;

      }
      &__input {
        border: none;
        outline: none;
        color: black;
        background-color: #ECF3FA;
        font-size: $caption-text;
      }
      &-with-unit {
        border-radius: 4px;
        outline: none;
      }
      &__label {
        font-size: 10px;
      }
    }
  }

  &--invalid.dark .number-input__input,
  &--invalid.light .number-input__input {
    border-color: $warning-red;
  }

  .number-input {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    .input-with-label {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    &__input {
      -moz-appearance: textfield;
      width: 120px;
      border: 1px solid $dark-gray;
      border-radius: 4px;
      padding: 2px 6px;
      font-size: $body-text;
      height: 32px;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &:disabled {
        background-color: $medium-charcoal;
        cursor: not-allowed;
        border: none;
        color: $very-lighter-gray;
      }
      &:hover, &:focus{
        @include applyTheme($themes) {
          border: 1px solid themed('contrast-light');
          outline: themed('contrast-light');
        }
      }
    }
    &__label {
      font-size: $body-text;
      color: inherit;
      margin-right: 40px;
    }

    &-container {
      display: flex;
    }

    &-with-unit {
      width: 75px;
    }

    &-unit {
      align-items: center;
      display: flex;
      font-size: $caption-text;
      height: 32px;
      justify-content: center;
      padding: 0 10px;
      width: 45px;
    }
  }
  .input-error {
    font-size: $caption-text;
    color: $warning-red;
    text-align: right;
    width: 100%;
    p {
      margin-left: 10px;
    }
  }
}
.number-input-all.non-form {
  .number-input-container {
    display: flex;
    width: 60%;
    justify-content: flex-start;
    padding: 0 30px;
  }
  .number-input {
    &__label {
      font-size: $body-text;
    }
    &__input {
      width: 120px;
      text-align: center;
    }
    &-unit {
      width: 80px;
    }
  }
}

// Dashed underline style overrides (panel type)
.panel {
  &.per-phase-row-input,
  &.single-row-input {
    &.number-input-all {
      .number-input__input {
        background-color: transparent;
        border: none;
        border-bottom: 1px dashed $dark-gray;
        border-radius: 0px;
        color: $medium-gray;
        display: flex;
        font-size: $caption-text;
        text-align: center;
        &:invalid {
          border-color: red;
        }

        &:disabled {
          background-color: transparent;
          border-bottom: 1px dashed transparent;
          cursor: default;
        }
      }

      .number-input__label {
        font-size: $caption-text;
      }

      .input-error {
        margin-top: 4px;
        p {
          margin-right: 50px;
        }
      }
    }

    &--editable.number-input-all .number-input__input {
      color: $white;
    }
    &--edited.number-input-all .number-input__input {
      border-bottom: 1px solid $light-teal;
    }
    &--invalid.number-input-all .number-input__input {
      border-bottom: 1px solid $warning-red;
    }
  }

  &.per-phase-row-input {
    .number-input, .number-input-container {
      margin: 0;
      width: 100%;
    }

    &.number-input-all {
      margin: 0;
      &.dark {
        .number-input__input, .number-input__input:disabled {
          background-color: transparent;
        }
      }
    }

    .number-input__input {
      align-items: center;
      height: 100%;
      justify-content: center;
      padding: 0px;
      width: 100%;
    }
  }

  &.single-row-input {
    color: $medium-gray;
    font-family: 'Roboto', sans-serif;
    margin: 5px 0;
    width: 100%;

    .number-input {
      margin: 0;
      width: 100%;
      align-items: flex-end;
    }

    .number-input-container {
      background-color: transparent;
      align-items: flex-end;
    }

    &.number-input-all {
      .number-input__input {
        height: 20px;
        width: 125px;
        padding: 0;
      }
      .number-input__label {
        padding-bottom: 4px;
      }

      .number-input-unit {
        background-color: transparent;
        width: 42px;
        color: $medium-gray;
        display: flex;
        padding-left: 10px;
        align-items: flex-end;
        height: 100%;
      }
      &.dark {
        .number-input__label {
          color: $medium-gray;
        }
      }
    }

    &--editable.number-input-all {
      .number-input__input {
        border-bottom: 1px dashed $dark-gray;
      }
      .number-input__label {
        padding-bottom: 0px;
      }
    }

    &--edited.number-input-all .number-input__input {
      border-bottom: 1px solid $light-teal;
    }
    &--invalid.number-input-all .number-input__input {
      border-bottom: 1px solid $warning-red;
    }
  }
}

.light.panel {
  &.per-phase-row-input, &.single-row-input {
    &.number-input-all {
      .number-input__input {
        color:  #262626;
      }
    }

    &--editable.number-input-all .number-input__input {
      color: #262626;
    }
  }

  &.single-row-input {
    color:  #262626;

    &.number-input-all {
      .number-input-unit {
        color:  #262626;
      }
    }
  }
}

.dark.panel {
  &.per-phase-row-input, &.single-row-input {
    &.number-input-all {
      .number-input__input {
        color: $white;
      }
    }
  }

  &.single-row-input {
    color: $white;
  }
}

.eq-lib.number-input-all {
  margin: 3px 0;
  display: flex;
  flex-direction: column;
  width: 575px;
  .number-input {
    display: flex;
    align-items: center;
    margin: 5px 0;
    width: 100%;
    .input-with-label {
      display: flex;
    }
    &__input {
      background-color: transparent;
      border: 1px solid $dark-gray;
      border-radius: 4px;
      padding: 2px 6px;
      font-size: $body-text;
      height: 32px;
      width: 225px;
      &::placeholder {
        color: $medium-gray-90;
      }

    }
    &__label {
      display: flex;
      align-items: center;
      font-size: inherit;
      white-space: nowrap;
      margin-right: 25px;
    }

    &-container {
      display: flex;
      width: 280px;
      .number-input-unit {
        display: flex;
        align-items: center;
        padding-left: 5px;
      }
    }
  }

  .input-error {
    font-size: $caption-text;
    color: $warning-red;
    text-align: right;
    width: 100%;
    padding-right: 50px;
  }

  &.dark {
    .number-input__input {
      border-color:  $dark-gray;
      color: $white;
      &:hover, &:focus {
        border: 1px solid $light-teal;
        outline: $light-teal;
      }
      &:disabled {
        background-color: $dark-gray;
        border-color:  $dark-gray;
        cursor: not-allowed;
        border: none;
        color: $very-lighter-gray;
      }
    }
    .number-input__label {
      color: $white;
    }
    .unit {
      color: $white;
    }
    .number-input-unit {
      background-color: transparent;
    }
  }

  &.light {
    .number-input__input {
      border-color: $light-gray;
      color: $dark-gray;
      &:hover, &:focus {
        border: 1px solid $light-teal;
        outline: $light-teal;
      }
      &:disabled {
        background-color: $light-gray;
        color: $dark-gray;
        cursor: not-allowed;
        border: none;
      }
    }
    .number-input-unit {
      background-color: transparent;
    }
  }

  &.modern {
    .number-input__input {
      border: none;
      outline: none;
      color: black;
      background-color: #ECF3FA;
    }
  }
}
