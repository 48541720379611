@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.radio-btn-select-group {
  display: flex;
  width: 100%;

  &--column {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    .option-group {
      margin: 3px 0;
    }
  }

  &--row {
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
  }

  .option-group {
    align-items: center;
    display: flex;
    flex-grow: 1;
    height: 42px;
  }

  .option-icon-group {
    color: $dark-gray;
    display: flex;
    align-items: center;
    user-select: none;
    span {
      flex-shrink: 0;
    }
  }

  .radio-btn {
    border: 2px solid $medium-gray;
    border-radius: 18px;
    display: flex;
    flex-shrink: 0;
    margin: 0 10px;
    height: 18px;
    width: 18px;

    &--selected {
      border-color: $light-teal;
      .radio-btn-fill {
        background-color: $light-teal;
      }
    }
  }

  .radio-btn-fill {
    border-radius: 15px;
    flex-grow: 1;
    margin: 2px;
  }

  .radio-btn-input {
    display: hidden;
    height: 0px;
    width: 0px;
    overflow: hidden;
    opacity: 0;

    &:focus + label .radio-btn {
      box-shadow: 0px 0px 1px 10px rgba(41,150,136,0.1);
    }

    &:disabled + label {
      .option-icon-group {
        color: $light-gray;
      }

      .radio-btn {
        border-color: $light-gray;
        &--selected .radio-btn-fill {
          background-color: $light-gray;
        }
      }
    }
  }

  .Select--single>.Select-control .Select-value {
    line-height: 37px;
  }

  .radio-select {
    margin-left: 15px;
    width: 165px;
  }

  .Select-control {
    border-top: none;
    border-bottom: none;
    border-radius: 0px;
  }

  .Select.is-open .Select-control {
    border: none;
  }

  /* Themes */

  &.dark {
    .option-icon-group {
      color: $medium-gray;
    }

    .radio-btn {
      &--selected {
        border-color: $light-teal;
        .radio-btn-fill {
          background-color: $light-teal;
        }
      }
    }

    .radio-btn-input:focus + label .radio-btn {
      box-shadow: 0px 0px 1px 10px rgba(6,175,186,0.1);
    }
  }

  &.light {
    .radio-btn {
      &--selected {
        border-color: $pacific-blue;
        .radio-btn-fill {
          background-color: $pacific-blue;
        }
      }
    }

    .radio-btn-input:focus + label .radio-btn {
      box-shadow: 0px 0px 1px 10px rgba(0,157,220,0.1);
    }
  }
}
