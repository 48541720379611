@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/_theme.scss';

.top-nav-container {
  background-color: $dark-charcoal;
  height: 50px;
  padding-top: 7px;
  @include applyTheme($themes) {
    background-color: themed('background-dark');
  }

  .nav-back__a {
    display: flex;
    align-items: center;
    color: $medium-gray;
    cursor: pointer;
    height: 50px;
    padding-left: 20px;
    text-decoration: none;
    width: 270px;
    @include applyTheme($themes) {
      color: themed('text-main');
    }
  
    &:hover {
      background-color: #555555;
      @include applyTheme($themes) {
        background-color: themed('background-dark');
      }  
    }
  }

  .nav-back-name {
    color: $white;
    font-size: $title-text;
    padding-left: 15px;
    @include applyTheme($themes) {
      color: themed('text-contrast');
    }  
  }
}
