@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.allocation-section {
  .content {
    padding: 5px 0;
  }
}

.load-generation {
  width: 100%;
  margin-bottom: 10px;
  .allocation-contents {
    width: 100%;
    transition: 1s ease max-height, visibility 0s linear 0.3s, opacity 0.3s;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    color: $medium-gray;
    max-height: 1000px;
    border-bottom: 1px solid $dark-gray;
    opacity: 1;
    @include applyTheme($themes) {
      color: themed('text-main');
    }
  
    .allocation-settings {
      display: flex;
      justify-content: space-between;
      &-col {
        width: 350px;
      }
    }
    
    .allocation-header {
      align-items: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      outline: none;
      padding: 0;
      width: 100%;
      h2 {
        font-size: $body-text;
        font-weight: normal;
        margin: 5px 0;
      }
    }
    
    .section-header {
      font-size: $body-text;
      font-weight: normal;
      margin: 10px 0;
    }
    
    .allocation-settings {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    
      .allocation-cell-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    
      .allocation-cell {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: $caption-text;
        height: 29px;
        justify-content: center;
        width: 50px;
        overflow: hidden;
    
        &-header {
          background-color: $medium-charcoal;
          width: 50px;
          margin: 0px;
          height: 20px;
          font-size: $caption-text;
          @include applyTheme($themes) {
            background-color: themed('background-neutral');
          }    
        }
    
        &-label {
          width: 75px;
          flex-shrink: 0;
        }
    
        &--error {
          color: $warning-red;
          height: 68px;
          .allocation-input {
            border-color: $warning-red;
            outline: none;
            margin-bottom: 5px;
          }
        }
        &__h3 {
          margin: 0;
          @include applyTheme($themes) {
            color: themed('text-contrast');
          }
        }
      }
    }
    
    .allocation-cell-message {
      color: $warning-red;
      font-size: $caption-text;
    }
    
    .allocation-row-label {
      font-size: $caption-text;
      align-self: flex-start;
      font-weight: normal;
    }
    
    .power-type-check-container {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    
    .power-type-checkbox {
      background-color: transparent;
      border: none;
      margin: 5px 0;
      &:first-of-type {
        margin-right: 25px;
      }
    }
    
    .power-type-label {
      display: inline-block;
      padding-left: 10px;
      @include applyTheme($themes) {
        color: themed('text-main');
      }
    }
    
    .allocation-submit {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      margin-top: 10px;
      width: 100%;
    }
    
    .allocation-feedback {
      align-self: flex-end;
      margin: 5px 0;
      height: 20px;
    }  
  }

}



