@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";


.workspace-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  width: 100%;
  flex-basis: 100%;

  .top-nav-container {
    width: 100%;
  }

  @include applyTheme($themes) {
    background-color: themed('background-very-dark');
  }

  .card {
    flex-grow: 1;
    width: 50%;
    height: 100%;
  }

  .content {
    height: 100%;
    width: 100%;
  }

  .list-container {
    width: 100%;
    padding-top: 10px;
    height: calc(100% - 54px);
  }

  .page-header {
    display: flex;
    flex-direction: row;
    width: 50%;
    align-items: center;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .page-title {
    font-size: $headline-text;
    font-weight: 500;
    text-align: center;
    align-items: center;
    padding: 12px;
    flex-grow: 1;
    margin: 0;
    padding: 0;

    @include applyTheme($themes) {
      color: themed('text-contrast');
    }
  }

  .create-button {
    position: absolute;
    right: 0;
    height: 40px;
    width: 125px;
  }

  .search-container {
    background-color: transparent;
  }

  .themed-tabs {
    height: 100%;
  }

  .workspace-card {
    border: 1px solid;
    display: flex;
    flex-direction: column;
    height: 50px;
    width: 100%;
    margin-bottom: 20px;

    &-open {
      height: 255px;
    }

    @include applyTheme($themes) {
      border-color: themed('background-light');
    }

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      padding-left: 10px;
      padding-right: 10px;

      .link {
        display: flex;
        flex-grow: 1;
      }
    }

    &-body {
      height: 200px;
      border-top: 1px solid;

      @include applyTheme($themes) {
        border-color: themed('background-light');
      }
    }

    &-title {
      font-size: $title-text;
      @include applyTheme($themes) {
        border-color: themed('text-contrast');
      }
    }

    &-tab-panel {
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 100%;

      .gis-tab, .column{
        width: 100%;
        padding: 0 10px;
        .gis-title{
          display: flex;
          margin: 10px 0;
        }
        textarea{
          padding: 5px;
          resize: none;
        }
      }
      .column {
        display: flex;
        flex-direction: column;
        width: 50%;

        .text-input__label {
          min-width: 100px;
          font-size: $body-text;
        }
      }

      .workspace-card-button {
        width: 155px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
}
