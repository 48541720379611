@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.asset-selector-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 45px;

  .left-label {
    width: 120px;
  }

  .dropdown {
    align-items: center;
    display: flex;
    flex-grow: 1;
    .info-tooltip {
      margin-left: 10px;
    }
  }
}
